import useSWR from "swr";
import { useState, useEffect } from 'react';
import { getAdminResource } from '../auth/services/message.service'
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from 'react';
import { XMLParser } from "fast-xml-parser";

const defaultMessages = {
  notFound: "Nenhum resultado para a consulta realizada.",
  default: "Erro 404. Página não encontrada."
}

export const fetcher = async (...args) => {
  try {
    const res = await fetch(...args);
    if (!res.ok) {
      const error = new Error(`HTTP error! status: ${res.status}, message: ${res.statusText}`);
      error.status = res.status;
      error.statusText = res.statusText;
      throw error;
    }
    return await res.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export function doFetchJson(response, messageSpec = defaultMessages) {
  if (response.ok && response.headers.get("content-type") === 'application/json') {
    return response.json();
  } else {
    switch (response.status) {
      case 404:
        throw new Error((messageSpec.notFound) ? messageSpec.notFound : defaultMessages.notFound);
      case 500:
        throw new Error((messageSpec.notFound) ? messageSpec.notFound : defaultMessages.notFound);
      default:
        throw new Error((messageSpec.default) ? messageSpec.default : defaultMessages.default);
    }
  }
}
// All Coins
export function useSymbolsNow(refreshInterval) {
  const { data, error, isLoading } = useSWR(process.env.REACT_APP_API_URL + "/v1/ticker/market-data", fetcher, {
    refreshInterval: refreshInterval,
  })

  const sortedCoins = useMemo(() => {
    return data?.sort((a, b) => b.QuoteVolume - a.QuoteVolume).map((item, index) => ({ ...item, Rank: index + 1 }));
  }, [data]);

  return {
    symbolsData: sortedCoins,
    isLoading,
    isError: error
  }
}

// All Exchanges (main page)
export function useExchangesNow() {
  const { data: exchanges, error, isLoading } = useSWR(process.env.REACT_APP_API_URL + "/v1/exchanges/all", fetcher, {
    refreshInterval: 15 * 60 * 1000, // 15 minutes cache
    revalidateOnFocus: false,
  });

  const sortedExchanges = useMemo(() => {
    return exchanges?.sort((a, b) => b.QuoteVolume - a.QuoteVolume).map((item, index) => ({ ...item, Rank: index + 1 }));
  }, [exchanges]);

  return {
    exchanges: sortedExchanges,
    isLoading,
    isError: error
  }
}

// WeekExchangeReport (Dominance)
export function useTwoWeekExchangeReport() {
  const { data, error, isLoading } = useSWR(process.env.REACT_APP_API_URL + "/v1/exchanges/dominance/week", fetcher, {
    refreshInterval: 2 * 60 * 60 * 1000, // 2 hours cache
    revalidateOnFocus: true,
  });

  return {
    data,
    isLoading,
    isError: error
  }
}

// Custom hook for fetching admin resources (StakedAreaChart, implement in others)
export const useAdminResource = (endpoint) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const fetchAdminResource = async () => {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently();
      } catch (error) {
        if (error.error === 'login_required') {
          // Ignore the error or handle it specifically
          setIsError(false);
          return;
        } else {
          console.error(error);
          setIsError(true);
          return;
        }
      }
      if (accessToken) {
        try {
          const response = await getAdminResource(accessToken, endpoint);
          if (response.status < 200 || response.status >= 300) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          setData(response.data);
        } catch (error) {
          console.error(error);
          setIsError(true);
        }
      }
    };

    fetchAdminResource().finally(() => {
      setIsLoading(false);
    });
  }, [endpoint, getAccessTokenSilently]);
  return { data, isLoading, isError };
};

// Custom fetcher for fetching RSS feeds with fallback
const fetcherXML = async (url) => {
  try {
    let response = await fetch(url);
    if (response.status === 404) {
      // If response status is 404, try the fallback URL
      response = await fetch(`${process.env.REACT_APP_API_URL}/v1/feed/noticias`);
    }
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const xml = await response.text();
    const parser = new XMLParser();
    return parser.parse(xml);
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const useRSSFeed = (slug) => {
  // Handle case where slug is undefined
  const finalSlug = slug ? slug.toLowerCase().split(" ")[0] : "noticias";

  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/v1/feed/${finalSlug}`,
    fetcherXML
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  };
};

// All Coins
export const useCoinList = () => {
  const { data, error } = useSWR(`${process.env.REACT_APP_API_URL}/v1/symbols`, fetcher, {
    refreshInterval: 24 * 60 * 60 * 1000, // 1 day cache
  })

  return { data, error };
};