export const groupBy = function (lista, column, times) {
  var columns = {};
  var resultado = [];

  lista.forEach(function (item) {
    var reg = {};

    columns[item[column]] = columns[item[column]] || [];

    for (var i in item)
      if (i !== column)
        reg[i] = item[i];

    columns[item[column]].push(reg);
  });

  for (var i in columns) {
    var result = { name: i, data: [] };

    for (var t in times) {
      var monthVolume = 0.0;

      for (var dataExchange in columns[i]) {
        if (columns[i][dataExchange].Time === times[t]) {
          monthVolume = columns[i][dataExchange].Volume
        }
      }

      result.data.push(monthVolume)
    }

    resultado.push(result).toFixed(5);
  }
  return resultado;
}
export const symbolsGroupBy = function groupBySymbol(data) {
  const resultQuoteVolume = {};
  const resultVolume = {};
  const dates = Array.from(new Set(data.map(item => item.Time)));

  dates.sort((a, b) => new Date(a) - new Date(b));

  data.forEach(item => {
    const symbol = item.Symbol;
    const dateIndex = dates.indexOf(item.Time);
    const quoteVolumeSum = item.QuoteVolume;
    const volumeSum = item.Volume;

    if (!resultQuoteVolume[symbol]) {
      resultQuoteVolume[symbol] = new Array(dates.length).fill(0);
    }
    resultQuoteVolume[symbol][dateIndex] = quoteVolumeSum;

    if (!resultVolume[symbol]) {
      resultVolume[symbol] = new Array(dates.length).fill(0);
    }
    resultVolume[symbol][dateIndex] = volumeSum;
  })

  return Object.keys(resultQuoteVolume, resultVolume).map(symbol => ({
    name: symbol,
    quoteVolume: resultQuoteVolume[symbol],
    volume: resultVolume[symbol]
  }));
}