import React, { useState, useEffect } from "react";
import { Nav, Row, Col, Container, Spinner, Tab, Badge, Breadcrumb, Table } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import ExchangeTable from './components/ExchangeTable'
import ComboChart from "./charts/ComboChart";
import Converter from "./components/Converter";
import coinlogo from "./components/logos/coinlogo.svg";
import NotFound from "./components/NotFound";
import NewsFeed from "./components/NewsFeed";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import SymbolHistoricalData from "./components/SymbolHistorical";
import { Helmet } from "react-helmet-async";
import useSWR, { mutate } from 'swr';
import { fetcher } from "./utils/api";
import ChartControl from "./charts/ChartControl";
import trustscore from "./data/trustscore.json";

function SymbolMarket() {

  const [frequency, setFrequency] = useState(300)
  const [interval, setIntervalsate] = useState(1440)
  const [active, setActive] = useState('1D')

  const symbol = useParams().Symbol.toUpperCase()
  const location = useLocation()

  const { data: exchanges, error: exchangeError } = useSWR(`${process.env.REACT_APP_API_URL}/v1/ticker/last/exchanges/${symbol}`, fetcher, {
    refreshInterval: 1000 * 60, // 60 sec cache
    retryCount: 3,
    revalidateOnFocus: true
  })
  const { data: coinsData, error: coinsError } = useSWR(`${process.env.REACT_APP_API_URL}/v1/ticker/last?symbols=BTCBRL,USDTBRL${symbol !== 'BTCBRL' ? ',' + symbol : ''}`, fetcher, {
    refreshInterval: 20000, // 20 sec cache
    retryCount: 3,
    revalidateOnFocus: true
  });

  // Handle loading state
  const loading = !exchanges || !coinsData;

  // Re-fetch data when location changes
  useEffect(() => {
    if (location.pathname) {
      mutate(`${process.env.REACT_APP_API_URL}/v1/ticker/last/exchanges/${symbol}`);
      mutate(`${process.env.REACT_APP_API_URL}/v1/ticker/last?symbols=BTCBRL,USDTBRL${symbol !== 'BTCBRL' ? ',' + symbol : ''}`);
    }
  }, [location, symbol]);

  if (loading && !exchangeError && !coinsError) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  }

  if (exchangeError) {
    if (exchangeError.status === 404) {
      return <NotFound />
    } else {
      console.log(exchangeError)
      return <div className="container text-center">Error fetching data: {exchangeError?.message || coinsError?.message}</div>;
    }
  } else {
    const coins = coinsData.filter(entry => entry.Symbol === symbol);
    const btc = coinsData.filter(entry => entry.Symbol === 'BTCBRL');
    const usdt = coinsData.filter(entry => entry.Symbol === 'USDTBRL');
    const symbolslug = JSON.parse(JSON.stringify(exchanges[0].SymbolName));

    const convertedTableData = exchanges.map(item => {
      return { ...item, Name: item.Exchange };
    });

    let btcvar = ((coins[0].Last) / btc[0].Last);
    let btcvar24 = ((coins[0]['24h']) / btc[0]['24h']);
    let usdtvar = ((coins[0].Last) / usdt[0].Last);
    let usdtvar24 = ((coins[0]['24h']) / usdt[0]['24h']);

    const ExchangeRateSpecification = {
      "@context": "https://schema.org",
      "@type": "ExchangeRateSpecification",
      "url": "https://mercadocripto.livecoins.com.br/" + exchanges[0].Symbol,
      "name": exchanges[0].SymbolName,
      "currency": exchanges[0].Symbol.slice(0, -3),
      "currentExchangeRate": {
        "@type": "UnitPriceSpecification",
        "price": coins[0].Last,
        "priceCurrency": "BRL"
      }
    };

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": exchanges[0].SymbolName,
          "item": "https://mercadocripto.livecoins.com.br/" + exchanges[0].Symbol,
        }
      ]
    };

    const FAQPage = {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Onde comprar " + exchanges[0].SymbolName + "?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A criptomoeda" + exchanges[0].Symbol.slice(0, -3) + " pode ser negociada em corretoras centralizadas. No Brasil, para comprar diretamente com BRL (reais), a corretora de maior volume é a " + exchanges[0].Exchange + ", onde o par " + exchanges[0].Symbol + " teve volume de " + exchanges[0].QuoteVolume + " nas últimas 24 horas."
          }
        },
        {
          "@type": "Question",
          "name": "Qual é o volume diário (últimas 24 horas) de negociação do " + exchanges[0].SymbolName + "(" + exchanges[0].Symbol + ") no Brasil em reais (BRL)?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "O volume de negociação do " + exchanges[0].Symbol + " nas últimas 24 horas foi " + (exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) + ", em um total de " + exchanges.length + " corretoras em atividade no Brasil."
          }
        }
      ]
    };

    return (
      <>
        <Helmet>
          <title>{exchanges[0].Symbol + " " + ((coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))) + " · " + exchanges[0].SymbolName + " · Preço em real brasileiro, volume e gráficos · MercadoCripto"}</title>
          <meta name="description" content={"O preço do " + exchanges[0].SymbolName + ", o volume negociado do " + exchanges[0].Symbol + " e o ágio em todas as corretoras do mercado brasileiro de criptomoedas. Acesso a gráficos do " + exchanges[0].Symbol.slice(0, -3) + " e dados históricos."} />
          <script type="application/ld+json">{JSON.stringify(ExchangeRateSpecification)}</script>
          <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
          <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
        </Helmet>
          <Container>
            <Breadcrumb className="pt-2">
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >
                Criptomoedas
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{exchanges[0].SymbolName}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="d-flex mb-2">
              <Col xs="3" md="auto" className="p-1 pl-3 py-0 symbolmarket-responsive">
                <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                  <title>{exchanges[0].SymbolName}</title>
                  <use href={coinlogo + "#" + (exchanges[0].Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                </svg>
              </Col>
              <Col>
                <h1 className="h2 text-nowrap ">{exchanges[0].SymbolName} <span className="text-muted h5"> {exchanges[0].Symbol.slice(0, -3)}</span></h1>
                <h2 className="h3 strong toupdate">{
                  (coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    : (0.1 <= coins[0].Last && coins[0].Last > 0.0001) ? ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 8 }))
                      : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 12 }))}
                </h2>
                {(exchanges[0].SymbolName === "Tether" || exchanges[0].SymbolName === "Binance USD" || exchanges[0].SymbolName === "USD Coin") ?
                  "" :
                  <h3 className="h6 text-muted strong ">{usdtvar.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} USDT
                    {(usdtvar - usdtvar24) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                    }
                  </h3>
                }
                {exchanges[0].SymbolName !== "Bitcoin" ?
                  <h3 className="h6 text-muted strong ">{btcvar.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 8, minimumFractionDigits: 5 })} BTC
                    {(btcvar - btcvar24) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                    }
                  </h3>
                  : ""}
              </Col>
              <Col className="ml-4 pt-3">
                <Table borderless responsive className="openmarket-table">
                  <tbody>
                    <tr>
                      <td><span className="h6">Abertura</span></td>
                      <td><span className="h6">{coins[0]['24h'].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></td>
                    </tr>
                    <tr>
                      <td><span className="h6">24h %</span></td>
                      <td>{(coins[0].Last - coins[0]['24h']) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                      </td>
                    </tr>
                    <tr>
                      <td><span className="h6">Var 24h</span></td>
                      <td>{(coins[0].Last - coins[0]['24h']) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col className="ml-4 pt-3">
                <Table borderless responsive className="openmarket-table">
                  <tbody>
                    <tr><td><span className="h6">Volume 24h</span></td></tr>
                    <tr><td><span className="h6 strong toupdate"> {exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /></span></td></tr>
                    <tr><td><span className="h6 text-muted toupdate">{exchanges.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 })} {exchanges[0].Symbol.slice(0, -3)} </span></td></tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className="pt-3">
              <Converter symbol={coins[0]} />
            </div>
          </Container>

        <Tab.Container justify defaultActiveKey="Corretoras" id="tab-markets" >
            <Container className="d-block px-0">
              <Nav variant="tabs pl-3 pt-2 fw-semibold">
                <Nav.Item>
                  <Nav.Link eventKey="Corretoras"><span className="text-body-secondary">Corretoras </span><Badge pill bg="gray" className="counter" title="Corretoras disponíveis">{exchanges.length}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Grafico"><span className="text-body-secondary">Gráfico</span></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Historicos"><span className="text-body-secondary d-block d-sm-none">Histórico</span><span className="d-none d-sm-block text-body-secondary">Dados Históricos</span></Nav.Link>
                </Nav.Item>
              </Nav>
            </Container>
          <Container>
            <Tab.Content className="mt-4">
              <Tab.Pane eventKey="Corretoras">
                <Container className="px-0 overflow-auto">
                  <ExchangeTable data={convertedTableData} price={coins[0]} trust={trustscore} visibleColumns={['Name', 'Spread', 'Last', 'QuoteVolume', 'Confiabilidade', 'VolumePercent']} />
                </Container>
              </Tab.Pane>

              <Tab.Pane eventKey="Grafico">
                <ChartControl
                  title={exchanges[0].SymbolName + ' e Real brasileiro'}
                  titleSecondary={exchanges[0].Symbol}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  interval={interval}
                  setIntervalsate={setIntervalsate}
                  active={active}
                  setActive={setActive}
                />
                <ComboChart symbol={symbol} frequency={frequency} interval={interval} last={coins[0].Last} />
              </Tab.Pane>

              <Tab.Pane eventKey="Historicos">
                <Container className="px-0 overflow-hidden">
                  <SymbolHistoricalData symbolname={exchanges[0].SymbolName} symbol={symbol} last={coins[0].Last} />
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </Tab.Container>
        <Container className="pt-4 ">
          <NewsFeed slug={symbolslug} />
        </Container>
      </>
    );
  }
}

export default SymbolMarket;