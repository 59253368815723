import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';

const popover = (
  <Popover id="popover-basic" trigger={["hover","focus"]}>
    <Popover.Body className='text-muted'>
      O indicador de confiabilidade é um algoritmo desenvolvido pelo Livecoins para avaliar a legitimidade dos dados disponibilizados pela corretora. A pontuação é calculada com base em uma série de métricas, como liquidez e estatísticas de tráfego. Para mais detalhes leia nossa <Link to="/metodologia" className="link-primary fw-semibold">metodologia</Link>.
    </Popover.Body>
  </Popover>
);

const OverlayHover = () => (
  <OverlayTrigger trigger={["hover","focus"]} placement="top" overlay={popover} delay={{ hide: 1000 }}>
      <span className="align-items-baseline">
        Confiabilidade <svg
          xmlns="http://www.w3.org/2000/svg"
          height="19px"
          viewBox="0 -960 960 960"
          className="pb-1"
          fill="currentColor"
        >
          <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
      </span>
  </OverlayTrigger>
);

export default OverlayHover;
