import { useTwoWeekExchangeReport } from '../utils/api';
import { useState, useEffect, useMemo } from 'react';
import ApexCharts from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import logo from '../components/logos/mercadocripto-logo.png';
import { useAdminResource } from '../utils/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useTheme } from '../components/Theme/ThemeContext';

const StackedAreaChart = ({ interval = 'week', frequency = 24, nowdata }) => {
    const [options, setOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const { user } = useAuth0();
    const { theme } = useTheme();

    const { data: weekData, isLoading: weekLoading, isError: weekError } = useTwoWeekExchangeReport(user);

    const adminResourceParams = useMemo(() => {
        if (interval !== 'week' && user) {
            return `dominance/chart?timerange=${interval}&frequency=${frequency}`;
        }
        return null;
    }, [interval, frequency, user]);

    const { data: adminData, isLoading: adminLoading, isError: adminError } = useAdminResource(adminResourceParams);

    const isLoading = interval === 'week' ? weekLoading : adminLoading;
    const isError = interval === 'week' ? weekError : adminError;
    const data = interval === 'week' ? weekData : adminData;

    useEffect(() => {
        // Set chart options
        setOptions({
            chart: {
                background: 'transparent',
                type: 'area',
                height: 400,
                stacked: true,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },

            tooltip: {
                x: {
                    show: true,
                    format: 'dd/MM/yyyy',
                },
                y: {
                    formatter: (value) => `${parseFloat(value / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 })}`,
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#f0b90b', '#2f78e2', '#4caf50', '#ff9800', '#3f51b5', '#ced4da'],
            fill: {
                type: 'solid',
                opacity: 0.8,
            },
            stroke: {
                curve: 'straight',
                width: 2,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontWeight: 600,
                itemMargin: {
                    horizontal: 10,
                    vertical: 15
                }
            },
            theme: {
                mode: theme,
            },
            xaxis: {
                labels: {
                    rotate: 0
                },
                tooltip: {
                    enabled: false
                },
                type: 'datetime'
            },
            yaxis: {
                opposite: true,
                max: 100,
                stepSize: 25,
                labels: {
                    formatter: (value) => { return (value / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 }) },
                },
            },
        });
    }, [theme]);

    useEffect(() => {
        if (!isLoading && data) {

            // Add the current data to the chart
            const currentData = nowdata.map((item) => {
                return {
                    Exchange: item.Name,
                    QuoteVolume: item.QuoteVolume,
                    Time: new Date().toJSON(),
                    Volume: item.QuoteVolume,

                };
            });
            data.push(...currentData)
            const transformData = (data) => {
                const groupedByTime = data.reduce((acc, curr) => {
                    const { Time, Exchange, QuoteVolume } = curr;

                    if (!acc[Time]) {
                        acc[Time] = [];
                    }

                    acc[Time].push({ Exchange, QuoteVolume });
                    return acc;
                }, {});

                const finalData = {};
                const allTimes = Object.keys(groupedByTime);

                // Get the top 4 exchanges
                const topExchanges = Array.from(new Set(data.map(item => item.Exchange)))
                    .sort((a, b) => {
                        const volumeA = data.filter(item => item.Exchange === a).reduce((sum, item) => sum + item.QuoteVolume, 0);
                        const volumeB = data.filter(item => item.Exchange === b).reduce((sum, item) => sum + item.QuoteVolume, 0);
                        return volumeB - volumeA;
                    })
                    .slice(0, 5);

                topExchanges.forEach(Exchange => {
                    finalData[Exchange] = {
                        name: Exchange,
                        type: "area",
                        data: []
                    };
                });

                finalData["Outros"] = {
                    name: "Outros",
                    type: "area",
                    data: []
                };

                Object.entries(groupedByTime).forEach(([time, exchanges]) => {
                    const exchangeVolumes = exchanges.reduce((acc, { Exchange, QuoteVolume }) => {
                        acc[Exchange] = (acc[Exchange] || 0) + QuoteVolume;
                        return acc;
                    }, {});

                    const totalVolume = Object.values(exchangeVolumes).reduce((sum, vol) => sum + vol, 0);

                    topExchanges.forEach(Exchange => {
                        const Volume = exchangeVolumes[Exchange] || 0;
                        const VolumePercent = (Volume / totalVolume) * 100;
                        finalData[Exchange].data.push({
                            x: time,
                            y: VolumePercent
                        });
                    });

                    const othersVolume = Object.values(exchangeVolumes).reduce((sum, vol, index) => {
                        if (!topExchanges.includes(Object.keys(exchangeVolumes)[index])) {
                            return sum + vol;
                        }
                        return sum;
                    }, 0);
                    const VolumePercent = (othersVolume / totalVolume) * 100;
                    finalData["Outros"].data.push({
                        x: time,
                        y: VolumePercent
                    });
                });

                // Fill in missing data points with zeros
                const maxLength = Math.max(...Object.values(finalData).map(series => series.data.length));
                Object.values(finalData).forEach(series => {
                    while (series.data.length < maxLength) {
                        series.data.push({
                            x: allTimes[series.data.length],
                            y: 0
                        });
                    }
                });

                return Object.values(finalData);
            };
            const seriesData = transformData(data);
            setChartSeries(seriesData);
        }
    }, [data, isLoading, nowdata]);

    if (!user && interval !== 'week') {
        return (
            <div className='text-center mb-3 bg-body-tertiary' style={{ height: '400px' }}>
                <h2 className="text-secondary pt-5">Conteúdo Exclusivo</h2>
                <p>Apenas o gráfico de 15 dias está disponível no plano gratuito.</p>
                <Link to='/assine' title="Assine" className="btn btn-lg btn-primary">Torne-se um assinante</Link>
            </div>
        );
    }

    if (isError) return <div>Error loading data</div>;
    if (isLoading) return <div className="text-center mt-5" style={{ height: "400px" }}><Spinner variant="primary" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner><br />Carregando... </div>;

    return (
        <div>
            <div id="chart" className="position-relative">
                <ApexCharts options={options} series={chartSeries} type="area" height={400} tabIndex="0" />
                <img src={logo} alt="MercadoCripto" className="position-absolute translate-middle-x opacity-75" style={{ width: '150px', right: '3%', top: '67%' }} />
            </div>
        </div>
    );
}

export default StackedAreaChart;
