import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Tooltip, Overlay, Badge, CloseButton  } from 'react-bootstrap';
import { ReactComponent as CopyIcon } from './components/svg/copy.svg';
import coinlogo from "./components/logos/coinlogo.svg";

// const useScript = url => {
//   useEffect(() => {
//     const script = document.createElement('script');

//     script.src = url;
//     script.async = true;

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     }
//   }, [url]);
// };

const Widget = (props) => {
  const [selectedCrypto, setSelectedCrypto] = useState(['BTCBRL', 'ETHBRL','USDTBRL']);
  const [theme, setTheme] = useState('light');
  const [coinList, setcoinList] = useState([]);
  //copy to clipboard
  const [show, setShow] = useState(false);
  const target = useRef(null);

  function handleSelectCypto(event) {
    setSelectedCrypto(prevSymbols => [...prevSymbols, event.target.value])
  }
  const removeCrypto = (event) => {
    setSelectedCrypto((prevState) =>
      prevState.filter((prevItem) => prevItem !== event.target.id)
    );
  };

  function handleSelectTheme(event) {
    setTheme(event.target.value);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/v1/symbols")
      .then(res => res.json())
      .then(res => setcoinList(res))
    document.title = "Widget de Preços de Criptomeodas · MercadoCripto"
  }, [])

  return (
    <Container className="p-3 mt-3 about">
      <h1 className="pb-4 h5 font-weight-bolder">Configure um widget para o seu website.</h1>
      <Row>
        <Col xs lg="5">
          <Form.Group controlId="cryptocurrencyselect">
            <Form.Label>Criptomoeda</Form.Label>
            <Form.Select value={selectedCrypto} onChange={handleSelectCypto} multiple={true}> 
              {coinList.map((data, index) => {
                if (data) {
                  return (
                    <option value={data.Symbol} key={data.Symbol}>{data.SymbolName + " - " + data.Symbol.slice(0, -3)}</option>
                  )
                }
                return null
              })}
            </Form.Select>
          </Form.Group>

          {selectedCrypto.map((i, idx) => (
            <Badge bg="body-secondary" className="me-2 mt-2 border-light" onClick={removeCrypto} id={i} key={i+"badge"}>
              <svg className="searchbarlogo" role="img" aria-labelledby="title">
                <title id="title">{i}</title>
                <use href={coinlogo + "#" + (i.slice(0, -3)).toLowerCase() + "-logo"}></use>
              </svg> <span className="text-muted">{i}</span>
              <CloseButton className="align-top" style={{ transform: "scale(0.7)"}} onClick={removeCrypto} id={i}/>
            </Badge>
          ))}

          <Form.Group className="pt-4" controlId="themeselect">
            <Form.Label>Tema</Form.Label>
            <Form.Select className="mt-1" value={theme} onChange={handleSelectTheme}>
              <option value='light' key='light'>Claro</option>
              <option value='dark' key='dark'>Escuro</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col ><p className="mb-2">Visualização do Widget</p>
          <div className="rounded p-4 small bg-body-tertiary border">
          <iframe title="mercadocripto-widget"
              style={{
                borderWidth: "0",
                boxSizing: "border-box",
                borderRadius: "4px",
                width: "350px",
                height: "230px",
                boxShadow: "rgb(0 0 0 / 5%) 0px 4px 10px"
              }} src={"https://mercadocripto.livecoins.com.br/app/widget.html?symbols=" + selectedCrypto.join(",") + "&theme=" + theme}>
            </iframe>
          </div>
          <p className="mt-3 mb-2 small">Código</p>
          <div className="rounded p-4 mt-0 small bg-body-tertiary border position-relative" style={{ cursor: "pointer" }} onClick={() => [navigator.clipboard.writeText(document.getElementById("code").innerText), setShow(!show), setTimeout(() => (setShow(false)), 2000)]} >
            <code lang="html" className="text-primary-emphasis text-break" id="code">
              &lt;iframe
              style="margin:30px;
              border-width: 0;
              box-sizing: border-box;
              border-radius: 4px;
              width:350px;
              height:230px;
              box-shadow: rgb(0 0 0 / 5%) 0px 4px 10px;
              border: 1px solid #dee2e6;"
              src="https://mercadocripto.livecoins.com.br/app/widget.html?symbols={selectedCrypto.join(",")}&theme={theme}"&gt;&lt;/iframe&gt;
            </code>
            <CopyIcon style={{ transform: "scale(0.5)", opacity: "0.6" }} className="position-absolute top-0 end-0" ref={target}>Copy</CopyIcon>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="copied" {...props}>
                  Texto copiado
                </Tooltip>
              )}
            </Overlay>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Widget