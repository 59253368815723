import React, { useState, useEffect } from "react";
import conteudoRestrito from './svg/conteudo-restrito.png';
import { Container, Row, Form, Col, Spinner } from 'react-bootstrap';
import { ReactComponent as LockFill } from './svg/lock-fill.svg';
import { Link } from "react-router-dom";

const Relatorios = (props) => {
  const now = new Date();

  const [selectedCrypto, setSelectedCrypto] = useState('?symbol=BTCBRL');
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(now.getFullYear());
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [coinList, setcoinList] = useState([]);
  const [allYears, setallYears] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  function handleSelectChange(event) {
    setSelectedCrypto(event.target.value);
  }
  function handleSelectChangeMonth(event) {
    setSelectedMonth(event.target.value);
  }
  function handleSelectChangeYear(event) {
    setSelectedYear(event.target.value);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/v1/symbols")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setcoinList(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    let startYear = 2021;
    const currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setallYears(years);
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
          <title>Dados mensais do volume negociado por cada corretora de criptomoeda no Brasil · MercadoCriptoBR"</title>
          <meta name="description" content="Relatórios mensais do volume negociado por cada corretoras de criptomoedas no Brasil, separado por par, moeda e volume. Ranking de corretoras brasileiras de criptomoedas e dados históricos de todas as corretoras do Brasil." />
        <div className="border-bottom py-4">
          <Container>
            <Row xs="auto">
              <Col xs={3} lg="auto"><h1 className="h3 font-weight-bolder"> <span className={!isSwitchOn ? 'text-body' : 'text-muted'}> Dados Mensais</span></h1></Col>
              <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="anual-mensal" aria-label="anual-mensal" checked={isSwitchOn} onChange={onSwitchAction} className="custom-control-input" /></Col>
              <Col xs={3} lg="auto"><h1 className="h3 font-weight-bolder"><span className={!isSwitchOn ? 'text-muted' : 'text-body'}>Dados Anuais</span></h1></Col>
            </Row>
            <p>Abaixo você encontra dados consolidados do volume negociado no mês de cada criptomoeda.<br /> Selecione a criptomoeda e o mês do seu interesse para gerar o gráfico e relatório.</p>
            <hr />
            <Row className="align-items-center">
              <Col sm={4} lg={3} className="my-1">
                <Form.Group controlId="cryptocurrencyselect">
                  <Form.Label>Criptomoeda</Form.Label>
                  <Form.Select value={selectedCrypto} onChange={handleSelectChange}>
                    <option value="all?symbol=BRLBRL" key="todas">Todas</option>
                    {coinList.map((data, index) => {
                      if (data) {
                        return (
                          <option value={"?symbol=" + data.Symbol} key={data.Symbol}>{(data.SymbolName !== null ? (data.SymbolName + " - ") : '') + data.Symbol.slice(0, -3)}</option>
                        )
                      }
                      return null
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              {!isSwitchOn ?
                <>
                  <Col sm={4} lg={3} className="my-1">
                    <Form.Group controlId="monthselect">
                      <Form.Label>Mês</Form.Label>
                      <Form.Select value={selectedMonth} onChange={handleSelectChangeMonth}>
                        <option value="1" key="1">Janeiro</option>
                        <option value="2" key="2">Fevereiro</option>
                        <option value="3" key="3">Março</option>
                        <option value="4" key="4">Abril</option>
                        <option value="5" key="5">Maio</option>
                        <option value="6" key="6">Junho</option>
                        <option value="7" key="7">Julho</option>
                        <option value="8" key="8">Agosto</option>
                        <option value="9" key="9">Setembro</option>
                        <option value="10" key="10">Outubro</option>
                        <option value="11" key="11">Novembro</option>
                        <option value="12" key="12">Dezembro</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4} lg={3} className="my-1">
                    <Form.Group controlId="yearselect">
                      <Form.Label>Ano</Form.Label>
                      <Form.Select value={selectedYear} onChange={handleSelectChangeYear}>
                        {allYears.map((data, index) => {
                          return (
                            <option value={data} key={data}>{data}</option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </>
                :
                ''
              }
            </Row>
          </Container>
        </div>
        <Container>
          <div className="position-relative text-center">
            <img src={conteudoRestrito} className="img-fluid m-4 w-75 opacity-75" title='Conteúdo Restrito para Assinantes' alt='Conteúdo Restrito para Assinantes' />
            <div className="position-absolute pb-5 top-50 start-50 translate-middle h3 fw-semibold">
              <div><LockFill className="text-muted searchad px-0 mx-0" /></div>
              <div className="text-muted">Conteúdo Restrito</div>
              <div><Link to='/assine' title="Assine" className="btn btn-lg btn-primary mt-2">Assine</Link></div>
            </div>
          </div>
        </Container>
      </>
    )
  }
}

export default Relatorios