import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  useEffect(() => {
    document.title = "Termos de Uso · MercadoCripto";
  }, []);
  return (
    <Container className="mt-3">
      <div className="mb-4">
        <h1 className="fw-semibold">Termos de Uso</h1>
        <p className="lead">
          Estes Termos de Uso regem o uso do serviço de dados de mercado
          fornecido pelo MercadoCripto. Ao prosseguir com o pagamento e utilizar
          nossos serviços, você concorda com os seguintes termos:
        </p>
        <h3 className="h5 fw-semibold">Serviço de Dados de Mercado</h3>
        <p>         
        Nossa empresa fornece acesso a dados de
          mercado mediante pagamento prévio. Os dados disponibilizados são de
          propriedade da nossa empresa e são destinados exclusivamente ao uso do
          cliente.
          </p>
          <h3 className="h5 fw-semibold">Pagamento e Acesso aos Dados</h3>
          <p>
            O cliente deve efetuar o pagamento
            conforme as condições estabelecidas para ter acesso aos dados de
            mercado disponibilizados pela nossa empresa.
          </p>
          <h3 className="h5 fw-semibold"> Uso dos Dados</h3>
          <p>
            Os dados de mercado fornecidos são para uso exclusivo
            do cliente e não podem ser compartilhados, vendidos ou utilizados
            para outros fins sem autorização prévia da nossa empresa.
          </p>
          <h3 className="h5 fw-semibold">Responsabilidade do Cliente</h3>
          <p>
             O cliente é responsável por garantir a
            segurança e confidencialidade dos dados de mercado obtidos através
            do nosso serviço.
          </p>
          <h3 className="h5 fw-semibold">Consentimento para Uso dos Dados</h3>
          <p>
            Ao utilizar nossos serviços, o
            cliente concorda em cumprir estes Termos de Uso e em não utilizar os
            dados de mercado de forma inadequada ou prejudicial.
          </p>
          <p>
            Ao prosseguir com o pagamento e utilizar nossos serviços de dados de
            mercado, você concorda com os termos estabelecidos acima. Em caso de
            dúvidas ou necessidade de esclarecimentos adicionais, entre em
            contato conosco.
          </p>
      </div>
    </Container>
  );
};

export default Terms;
