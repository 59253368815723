import React, { useState } from 'react';
import useSWR from 'swr';
import SearchBar from "./SearchBar";
import CoinList from "./CoinList";
import { fetcher,useCoinList } from '../../utils/api';

const SearchPage = () => {
    const [input, setInput] = useState('');
    const [coinList, setCoinList] = useState();
    const [exchangeList, setExchangeList] = useState();

    // Use SWR to fetch coin and exchange data
    const { data: coinListDefault, error: coinError } = useCoinList();
    const { data: exchangeListDefault, error: exchangeError } = useSWR(`${process.env.REACT_APP_API_URL}/v1/exchanges/`, fetcher);

    // Handle loading state
    const loading = !coinListDefault && !exchangeListDefault;

    const updateInput = (input) => {
        if (coinListDefault) {
            const notnull = coinListDefault.filter((val) => val.SymbolName !== null || val.Symbol !== null);
            const filtered = notnull.filter(coin => {
                return coin.SymbolName?.toLowerCase().includes(input.toLowerCase()) || coin.Symbol.slice(0, -3).toLowerCase().includes(input.toLowerCase());
            });
            const notnullexchange = exchangeListDefault.filter((val) => val.SymbolName !== null);
            const filteredexchanges = notnullexchange.filter(exchange => {
                return exchange.Name.toLowerCase().includes(input.toLowerCase());
            });
            setInput(input);
            setCoinList(filtered);
            setExchangeList(filteredexchanges);
        } else return null;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (coinError || exchangeError) {
        return <div>Error loading data</div>;
    }

    if (input.length !== 0) {
        return (
            <>
                <SearchBar
                    input={input}
                    onChange={updateInput}
                />
                <CoinList onClick={updateInput} coinList={coinList} exchangeList={exchangeList} />
            </>
        );
    } else {
        return (
            <SearchBar
                input={input}
                onChange={updateInput}
            />
        );
    }
};

export default SearchPage;
