import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '../components/Theme/ThemeContext';

const ExchangesDonut = ({ data, othersVolume = 0 }) => {
  const { theme } = useTheme();
  const [chartData, setChartData] = useState({
    series: [],
    options: {}
  });

  useEffect(() => {
    let sortedData = [...data].sort((a, b) => b.QuoteVolume - a.QuoteVolume);

    if (sortedData.length > 6) {
      let others = {
        "Symbol": "Outros",
        "SymbolName": "Outros",
        "Last": 0,
        "Volume": 0,
        "QuoteVolume": 0
      };

      for (let i = 6; i < sortedData.length; i++) {
        others.QuoteVolume += sortedData[i].QuoteVolume;
        others.Volume += sortedData[i].Volume;
        others.Last = (others.Last * (i - 6) + sortedData[i].Last) / (i - 5);
      }

      others.QuoteVolume += othersVolume

      sortedData = sortedData.slice(0, 6);
      sortedData.push(others);
    }

    const pieData = {
      labels: sortedData.map(item => item.Symbol),
      series: sortedData.map(item => item.QuoteVolume)
    };

    // Calculate total volume
    const totalVolume = pieData.series.reduce((acc, value) => acc + value, 0);

    setChartData({
      series: pieData.series,
      options: {
        chart: {
          type: 'donut',
        },
        labels: pieData.labels,
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#008080', '#C4BBAF'],
        tooltip: {
          theme: theme,
          fillSeriesColor: false,
          y: {
            formatter: (value, { seriesIndex }) => {
              const percentage = ((value / totalVolume) * 100).toFixed(1); // Calculate percentage
              return `${value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL',minimumFractionDigits:1 , maximumFractionDigits: 1  })} (${percentage}%)`; // Format tooltip
            }
          }
        },
        stroke: {
          width: 0.5,
          colors: [theme === 'dark' ? '#262626' : 'f8f9fa'],
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
            }
          }
        },
        theme: {
          mode: theme, 
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#0c0c0c']
          },
          dropShadow: {
            enabled: false,
          },
          formatter: (val, opts) => {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + '%'];
          }
        },
        legend: {
          offsetY: -20,
          itemMargin: {
            horizontal: 10,
            vertical: -2
          },
          formatter: function (seriesName, opts) {
            const percentage = ((opts.w.globals.series[opts.seriesIndex].toFixed(2) / totalVolume)).toLocaleString('pt-BR', { style: 'percent',minimumFractionDigits:1, maximumFractionDigits: 1 });
        
            // Return a styled string with Bootstrap classes
            return `
              <div class="d-flex justify-content-between align-items-center text-body-secondary" style="white-space: nowrap;">
                <div class="d-flex align-items-center">
                  <span class="me-2" style="background-color: ${opts.w.globals.colors[opts.seriesIndex]}; width: 10px; height: 10px; display: inline-block; border-radius: 50%;"></span>
                  <span>${opts.w.globals.labels[opts.seriesIndex]}</span>
                </div>
                <span class="text-end ms-5">${percentage}</span>
              </div>
            `;
          },
          fontWeight: 600,
          horizontalAlign: 'left',
          show: true
        }
      }
    });


  }, [data, othersVolume,theme]);

  return (
    <div className="mx-auto w-100">
      <span className="h5 fw-semibold pb-5 ms-4">Volume por criptomoeda</span>
      <Chart options={chartData.options} series={chartData.series} id="donut" className='mt-3' type="donut" height="245" width="525" />
    </div>
  );
};

export default ExchangesDonut;
