import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { useTheme } from '../components/Theme/ThemeContext';
import OverlayHover from "../components/OverlayTrustScore";

const RadialBarChart = (props) => {
    const { theme } = useTheme();
    const [state, setState] = useState({
        series: [],
        options: {}
    })

    const getColors = (data) => {
        if (data >= 70) {
            return ['#01a83f']; // green
        } else if (data >= 50 && data < 70) {
            return ['#ff9a05']; // yellow
        } else {
            return ['rgb(202, 48, 62)']; // red
        }
    }

    useEffect(() => {
        setState({
            series: [props.data],
            options: {
                chart: {
                    background: 'transparent',
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '65%',
                        },
                        dataLabels: {
                            name: {
                                show: true,
                                fontSize: '34px',
                                fontFamily: undefined,
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -0
                              },
                            value: {
                                show: true,
                                fontSize: '15px',
                                fontFamily: 'Arial',
                                fontWeight: 600,
                                color:"#888888",
                                formatter: function (val) {
                                    return "Confiabilidade"
                                }
                            },
                        },
                        track:{
                            opacity: 0.5,
                            background: '#888'
                        }
                    },
                },
                stroke: {
                    lineCap: 'round'
                },
                theme: {
                    mode: 'theme',
                },
                labels: [props.data/10 + '/10'],
                colors: getColors(props.data),
            },
        })
    }, [theme, props.data]);



    return (
        <div className="mx-auto text-center"    >
            <span className="h5 fw-semibold">Nota de <OverlayHover/></span>
            <Chart options={state.options} series={state.series} type="radialBar" height={300} />
        </div>
    );
}

export default RadialBarChart;
