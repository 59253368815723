import React, { useState, useEffect } from "react";
import { getAdminResource } from "../../../auth/services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Spinner, FigureCaption } from 'react-bootstrap';
import { groupBy } from '../../../utils/groupBy';
import Treemap from "../../../charts/ExchangeTreeMap";
import ExchangeTable from "../../ExchangeTable";

const MonthDashboard = (props) => {
    document.title = "Dashboard Plano Empresarial · MercadoCripto";
    const { setTotalMonthVolume } = props;

    const now = new Date();
    const yesterday = now.setDate(now.getDate() - 1);
    const month = new Date(yesterday).getMonth() + 1;
    const year = new Date(yesterday).getFullYear()

    const [exchangeNumber, setExchangeNumber] = useState(5);
    const { getAccessTokenSilently } = useAuth0();
    const [treeChartSeries, setTreeChartSeries] = useState([]);
    const [tableSeries, setTableSeries] = useState([]);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        setExchangeNumber(props.isExchFive ? 5 : 30);
    }, [props.isExchFive]);

    useEffect(() => {
        const fetchReport = async () => {
            const accessToken = await getAccessTokenSilently();
            setIsLoaded(false);
            setError(null);

            try {
                const response = await getAdminResource(accessToken, `monthreportall?symbol=BRLBRL&month=${month}&year=${year}`);

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = response.data;

                const xaxistimeUTC = data.map(value => value.Time).filter((value, index, arr) => arr.indexOf(value) === index);
                const monthreport = groupBy(data, "Exchange", xaxistimeUTC);
                const totalVolume = monthreport.reduce((acc, current) => acc + current.data.reduce((acc, current) => acc + current, 0), 0);
                setTotalMonthVolume(totalVolume)
                const treeChartData = monthreport.map(item => {
                    const sum = item.data.reduce((acc, current) => acc + current, 0);
                    const volumePercent = (sum / totalVolume) * 100;
                    return { Name: item.name, QuoteVolume: sum, Volume: 0, YesterdayVolume: 0, VolumePercent: volumePercent / 100 };
                });

                setTreeChartSeries(treeChartData);
                setTableSeries(treeChartData.sort((a, b) => b.VolumePercent - a.VolumePercent).slice(0, exchangeNumber))
                setIsLoaded(true);
            } catch (error) {
                console.error(error);
                setError(error);
                setIsLoaded(true);
            }
        };

        fetchReport();
    }, [exchangeNumber, getAccessTokenSilently, month, year]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
    } else {
        return (
            <>
                <ExchangeTable data={tableSeries} fees={props.fees} visibleColumns={["Name", "QuoteVolume", "VolumePercent", "Fees", "Lucro"]} />
                <FigureCaption>
                    *   As taxas e lucros são calculados pela soma das taxas <em className="fw-semibold text-primary">Taker</em> e <em className="fw-semibold text-primary">Maker</em>.
                </FigureCaption>
                <Treemap data={treeChartSeries} />
            </>
        )
    }
}

export default MonthDashboard