import React from 'react';
import { ReactComponent as Caret } from '../components/caret-up-fill.svg';

// VariationIndicator Component
export const VariationIndicator = ({ valueToday, valueYesterday }) => {
    const valueChange = (valueToday - valueYesterday) / valueYesterday;
    const volumeVar = valueChange.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });

    return (
        <span className={`text-nowrap ${valueChange >= 0 ? 'text-success' : 'text-danger'}`}>
            {valueChange >= 0 ? <Caret className="icontable" /> : <Caret className="icon-rotated" />}
            {valueChange >= 0 ? volumeVar : volumeVar.substring(1)}
        </span>
    );
};

export function formatBigNumbers (val) { 
    if (val >= 1e9) {
      return (val / 1e9).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'B'; // For billions
    } else if (val >= 1e6) {
      return (val / 1e6).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'M'; // For millions
    } else {
      return val.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }); // For smaller numbers
    }
  }

const exports = {
    VariationIndicator,
    formatBigNumbers
};

export default exports;
