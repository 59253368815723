import React, { useState, useEffect } from "react";
import { Container, Spinner } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import { Helmet } from "react-helmet-async";
import { doFetchJson } from "./utils/api";

const Graficos = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [topData, setTopData] = useState()

    const colors =
        ['#4328e7', '#ffc502', '#ff8800', '#f90103', '#007d8e', '#1aa7ee', '#9654e5',
            '#ff6283', '#1eb980', '#8d8f91'];
    const options = {
        sankey: {
            node: {
                colors: colors,
            },
            link: {
                colorMode: 'target',
                colors: colors,
            }
        },
        tooltip: {
            textStyle: {
                fontName: 'Arial',
                fontSize: 13
            }, showColorCode: true
        }
    };


    useEffect(() => {
        const getData = () => {
            fetch(process.env.REACT_APP_API_URL + "/v1/symbols/top")
                .then(res => doFetchJson(res))
                .then(res => {
                    var sortedres = res.sort((a, b) => a.Symbol.localeCompare(b.Symbol))
                    var topdata = (sortedres.map(x => [x.Symbol, x.Name, +(x.QuoteVolume / 1000000).toFixed(2)]))
                    setTopData([["Moeda", "Corretora", "Volume (BRL)"], ...topdata])
                    setIsLoaded(true)
                })
                .catch((error) => {
                    setError(error)
                    setIsLoaded(true)
                })
        };
        getData();
    }, []);


    if (error) {
        return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">{error.message}</h5></Container>;
    } else if (!isLoaded) {
        return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
    } else {
        return (
            <>
                <Helmet>
                    <title>Os 15 pares mais negociados no Brasil · MercadoCripto"</title>
                    <meta name="description" content="Relatórios mensais do volume negociado por cada corretoras de criptomoedas no Brasil, separado por par, moeda e volume. Ranking de corretoras brasileiras de criptomoedas e dados históricos de todas as corretoras do Brasil." />
                </Helmet>
                <Container className="p-3 mt-3">
                    <div className="col-lg-7">
                        <h1 className="h3 fw-bold">Os 15 pares mais <span className="underline-success">negociados</span> no Brasil</h1>
                        <span className="small text-muted">Volume diário (em milhões de reais BRL)</span>

                        <div className="mt-2 img-fluid">
                            <Chart
                                chartType="Sankey"
                                width="100%"
                                height="30rem"
                                data={topData}
                                options={options}
                            />
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default Graficos