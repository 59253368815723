import React, { useState, useEffect } from "react";
import { Navbar, Offcanvas, Nav, Container, CloseButton } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import SearchPage from "./SearchBar/SearchPage";
import logo from './logos/mercadocripto-logo.png';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Caret } from './caret-up-fill.svg';
import { NavBarButtons } from "./buttons/nav-bar-buttons";
import ThemeToggle from "./Theme/ThemeToggle";

const NavBar = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(false)
  }, [location]);

  const menuItems = [
    { label: 'Corretoras', to: '/' },
    { label: 'Criptomoedas', to: '/criptomoedas' },
    { label: 'Dados Mensais', to: '/relatorios' },
    { label: 'Dominância', to: '/dominancia' },
    { label: 'DCA', to: '/DCA' },
  ];

  return (
    <>
      <Navbar id="topnav" collapseOnSelect expand="xl" bg="primary" variant="dark" className="text-nowrap">
        <Container className="pr-5 pl-2" >
          <Navbar.Brand as={NavLink} to="/" alt="MercadoCripto">
            <img src={logo} className="d-inline-block align-middle" alt="mercadocripto" title="mercadocripto" width="170" height="25.5" />{' '}
          </Navbar.Brand>

          {/* Desktop Menu */}
          <div className="d-none d-xl-block  me-auto ">
            <Nav className="my-2 justify-content-evenly" style={{ maxHeight: '100px' }}>
              {menuItems.map((item, index) => (
                <Nav.Link
                  key={index}
                  as={NavLink}
                  to={item.to}
                  className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                  end={index === 0}
                >
                  {item.label}
                </Nav.Link>
              ))}
            </Nav>
          </div>
          <div className="d-flex mt-2 pb-1 d-none d-xl-block pe-2 me-2">
            <NavBarButtons />
          </div>
          <div className="d-flex mt-2 pb-1 d-none d-xl-block" id="navSearch">
            <SearchPage />
          </div>
          <div className="d-xl-none">
            <NavBarButtons />
          </div>
          <div className="d-none d-xl-block">
            <ThemeToggle />
          </div>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleShow} />
          <Navbar.Offcanvas show={show} onHide={handleClose} id="offcanvasNavbar" className="bg-body d-xl-none" aria-labelledby="responsive-navbar-nav" placement="end" scroll>
            <Offcanvas.Header id="offcanvasnav" className="bg-primary pl-3" style={{ paddingTop: "2.2rem", paddingBottom: "2.2rem" }}>
              <Offcanvas.Title id="responsive-navbar-nav">
                <img src={logo} className="d-inline-block align-top" alt="mercadocripto" title="mercadocripto" width="170" height="25.5" />
              </Offcanvas.Title>
              <div className="mx-auto">
              <ThemeToggle/>
              </div>
              <CloseButton onClick={handleClose} className="float-end mr-0 btn-close-white" />
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">

              <div className="d-flex py-5 w-100 px-3 border-bottom">
                <SearchPage />
              </div>

              {/* Mobile menu */}
              <Nav className="mr-auto pt-2 px-3">
                {menuItems.map((item, index) => (
                  <div key={index} className="mr-auto my-4" style={{ transform: "rotate(0)" }}>
                    <Nav.Link
                      as={NavLink}
                      to={item.to}
                      className="mr-auto text-primary stretched-link"
                      onClick={handleClose}
                    >
                      {item.label}
                    </Nav.Link>
                    <Caret className="text-primary icon-rotated-offcanvas float-end" />
                  </div>
                ))}

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
export default NavBar;