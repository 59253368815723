import React, { useState } from "react";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table'
import { Link } from "react-router-dom";
import { ProgressBar, Table } from 'react-bootstrap';
import exchangelogo from "./logos/exchangelogo.svg";
import coinlogo from "./logos/coinlogo.svg";
import OverlayTrustScore from "./OverlayTrustScore";
import { VariationIndicator } from "../utils/priceFormats";
import fees from '../data/fees.json';
import trustscore from '../data/trustscore.json';
import { useTheme } from './Theme/ThemeContext';

const ExchangesTable = (props) => {
  const [tableKey, setTableKey] = useState(0);
  const { columnVisibility = {}, visibleColumns = [] } = props;
  const { theme } = useTheme();

  const price = props.price ?? { Last: 0 };
  const exchanges = props.data

  const handleFeeChange = (name, value) => {
    setTaxas(prev => ({
      ...prev,
      [name]: value
    }));
    setTableKey(tableKey + 1);
  };

  const [taxas, setTaxas] = useState(
    fees.reduce((acc, fee) => {
      acc[fee.nome] = fee.taker + fee.maker;
      return acc;
    }, {})
  );

  const columns = React.useMemo(() => [
    {
      header: '#',
      accessorKey: 'Rank',
      enableSorting: true,
    },
    {
      header: () => (
        <span data-accessor="sticky">Corretora</span>
      ),
      accessorKey: 'Name',
      headerClassName: 'corretoras-header',
      cell: info => {
        const name = info.row.original.Name;
        return (
          <Link to={'/corretoras/' + name} className="text-body">
            <svg className="tablelogo" role="img" aria-labelledby="title">
              <title id="title">{name}</title>
              <use href={exchangelogo + "#" + (name.toLowerCase())}></use>
            </svg>
            {name}
          </Link>
        )
      }
    }, {
      header: 'Preço',
      accessorKey: 'Last',
      cell: info => (
        <>
          {(info.getValue() > 0.1) ? info.getValue().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + info.getValue().toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))}
        </>
      ),
    }, {
      header: 'Ágio',
      accessorFn: row => ((row.Last - price.Last) / price.Last),
      accessorKey: 'Spread',
      enableSorting: true,
      cell: info => (
        <span>
          <VariationIndicator valueToday={info.row.original.Last} valueYesterday={price.Last} />
        </span>
      )
    }, {
      header: 'Prova de Reservas',
      accessorFn: row => (trustscore.some(i => i.name === row.Name && i.reserve)) ? 1 : 0,
      accessorKey: 'reserves',
      enableSorting: true,
      cell: info => {
        const hasProof = trustscore.some(i => i.name === info.row.original.Name && i.reserve);
        return <span className="text-center d-flex justify-content-center">{hasProof ?
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor" className="text-success"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>
          : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor" className="text-danger"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
        }</span>;
      },
    }, {
      header: <OverlayTrustScore />,
      accessorFn: row => trustscore.find(i => i.name === row.Name)?.trustscore,
      accessorKey: 'Confiabilidade',
      enableSorting: true,
      cell: info => {
        const trustScore = trustscore.find(i => i.name === info.row.original.Name)?.trustscore;
        const className = trustScore < 5 ? "text-danger bg-danger-subtle" : trustScore >= 7 ? "text-success bg-success-subtle" : "text-warning bg-warning-subtle";
        return (
          <span className="text-center d-flex justify-content-center">
            <span className={`fs-7 px-2 py-1 fw-semibold ${className} rounded-2 border-0`}>{trustScore + "/10"}</span>
          </span>
        )
      }
    }, {
      header: 'Domicílio',
      accessorFn: row => trustscore.find(i => i.name === row.Name)?.nacional,
      accessorKey: 'Domicilio',
      enableSorting: true,
      cell: info => {
        const isNational = trustscore.some(i => i.name === info.row.original.Name && i.nacional);
        return <span className="text-center d-flex justify-content-center">{isNational ?
          <svg className="searchbarlogo" role="img" aria-labelledby="title">
            <title id="title">Nacional</title>
            <use href={coinlogo + "#brl-logo"}></use>
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" className="searchbarlogo" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
            <title id="title">Internacional</title>
            <path d="M40-320v-320h80v320H40Zm120 0v-320h80l89 178-9 2v-180h80v320h-80l-89-178 9-2v180h-80Zm360 0v-240h-80v-80h240v80h-80v240h-80Zm200 0v-320h80v240h120v80H720Z" />
          </svg>}
        </span>
      }
    }, {
      header: '#Moedas(BRL)',
      accessorKey: 'Nsymbols',
      enableSorting: true,
      cell: info => (
        <span className="text-center d-flex justify-content-center">
          {parseInt(info.getValue())}
        </span>
      ),
    }, {
      header: 'Volume(24h)',
      accessorKey: 'QuoteVolume',
      enableSorting: true,
      cell: info => (
        <>
          <div>{parseFloat(info.getValue()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
          {info.row.original.Volume ? <span className="text-muted small">{parseFloat(info.row.original.Volume)?.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }) + " " + info.row.original.Symbol.slice(0, -3)}</span>: null}
        </>
      ),
    }, {
      header: 'Vol(24h)%',
      accessorFn: row => ((row.QuoteVolume - row.YesterdayVolume) / row.YesterdayVolume),
      accessorKey: '24h',
      enableSorting: true,
      cell: info => (
        <span>
          <VariationIndicator valueToday={info.row.original.QuoteVolume} valueYesterday={info.row.original.YesterdayVolume} />
        </span>
      )
    }, {
      header: 'Volume(%)',
      accessorKey: 'VolumePercent',
      enableSorting: true,
      cell: info => {
        const max = Math.max.apply(Math, exchanges.map(function (e) { return e.VolumePercent * 100; }));
        return (
          <ProgressBar max={max} now={info.getValue() * 100} style={{ minWidth: "2em" }} variant={`${theme === 'dark' ? 'primary' : 'info'}`} label={`${info.getValue().toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
        )
      }
    }, {
      header: 'Taxas(%)',
      accessorKey: 'Fees',
      enableSorting: true,
      cell: info => {
        return (
          <input
            className="form-control-sm form-control"
            type="number"
            step="0.01"
            placeholder={parseFloat(taxas[info.row.original.Name] || 0).toFixed(2)}
            onBlur={(e) => handleFeeChange(info.row.original.Name, parseFloat(e.target.value) || 0)}
          />
        )
      }
    }, {
      header: 'Lucro',
      accessorKey: 'Lucro',
      enableSorting: true,
      cell: info => (
        <>
          {(parseFloat(taxas[info.row.original.Name]) / 100 * info.row.original.QuoteVolume).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </>
      ),
    }
  ], [exchanges, price.Last, taxas, theme]);

  const table = useReactTable({
    data: exchanges,
    columns: visibleColumns.length > 0 ? columns.filter(col => visibleColumns.includes(col.accessorKey)) : columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility: visibleColumns.length > 0 ? visibleColumns.reduce((acc, col) => ({ ...acc, [col]: true }), {}) : columnVisibility,
      sorting: [
        {
          id: 'VolumePercent',
          desc: true,
        },
      ],
    },
  });

  return (
    <div className="react-bootstrap-table table-responsive">
      <Table hover responsive id="exchangeTable">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="header-class text-nowrap">
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className={`bg-body-tertiary text-body-secondary py-4 ${index === 0 ? 'ps-2' : ''}`}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none d-inline-block'
                            : 'd-inline-block'
                        }
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <svg role="button" width="15px" height="15px" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.9 15L12 6.9l8.1 8.1z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
                          ,
                          desc: <svg role="button" fill="currentColor" width="15px" height="15px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g><polygon points="8 3 5 7 2 3 8 3" /></g></svg>
                          ,
                        }[header.column.getIsSorted()] ??
                          <svg role="button" width="15px" height="15px" viewBox="0 0 24 24" fill="#ccc" xmlns="http://www.w3.org/2000/svg"><path d="M18.1 15L12 21.1 5.9 15zm0-6L12 2.9 5.9 9z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
                        }
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className="border-bottom border-light-subtle text-body">
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={`${ exchanges[0].Volume ? '':'py-3'} text-nowrap`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
};

export default ExchangesTable;