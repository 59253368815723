import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap";
import { ReactComponent as Check } from "./components/svg/check2.svg";
import PayModal from "./utils/PayModal";

const planPrices = [
  {
    pricey: 21600,
    price: 1800,
  },
  {
    pricey: 15000,
    price: 1250,
  },
];

const plansTypes = [
  {
    name: "Plano Gratuito",
    theme: "",
    price: 0,
    benefits: ["Acesso ao conteúdo gratuito do MercadoCripto."],
  },
  {
    name: "Plano Pro",
    theme: "warning",

    benefits: [
      "Acesso livre aos relatórios mensais e anuais de volume dos últimos 12 meses.",
      "Download no formato .CSV e Excel",
      "Download no formato .json",
    ],
    button: "Assine",
  },
  {
    name: "Plano Empresarial",
    theme: "primary",
    price: "Personalizado",
    benefits: [
      "Acesso completo aos dados históricos.",
      "Comparações de volume entre corretoras.",
      "Download no formato .CSV e Excel",
      "Download no formato .json",
      "Relatórios personalizados de acordo com a sua necessidade.",
      "Gráficos produzidos sob demanda.",
    ],
    button: "Consulte",
  },
];

const Assine = () => {
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Pagamento Mensal", value: "0" },
    { name: "Pagamento Anual", value: "1" },
  ];

  useEffect(() => {
    document.title = "Seja um assinante · MercadoCripto";
  }, []);
  return (
    <Container className="p-3 mt-3 text-center">
      <div className="h4 fw-semibold">Conheça nossos serviços.</div>
      <h1 className="display-4 fw-semibold">Assine o MercadoCripto</h1>
      <div className="py-1  fw-semibold">
        <p>
          Faça a assinatura anual e economize até{" "}
          <span className="text-secondary fw-semibold">30%</span>
        </p>
      </div>

      <ButtonGroup className="mb-2">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-primary"
            name="radio"
            className="fw-semibold"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <Row xs={1} md={3} className="g-5 text-start pb-5">
        {plansTypes.map((i, idx) => (
          <Col key={idx}>
            {idx === 1 ? (
              <div className="py-2 rounded-top bg-primary bg-gradient text-bg-primary text-center assinebanner">
                Mais Popular
              </div>
            ) : (
              <div className="py-2 assinebanner">&nbsp;</div>
            )}
            <Card className="bg-body-tertiary h-100">
              <Card.Body className="h-100 d-flex  flex-column">
                <Card.Title>
                  <span className={`fw-bold text-${i.theme}`}>{i.name}</span>
                  <h2 className="h1 fw-semibold">
                    {idx === 1 ? (
                      <>
                        {planPrices[radioValue].price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        <span className="fs-6 text-muted">/mês</span>
                      </>
                    ) : (
                      i.price.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </h2>
                  {idx === 1 ? (
                    <p className="text-muted fw-semibold">
                      {planPrices[radioValue].pricey.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      <span className="fs-6">/ano</span>
                    </p>
                  ) : (
                    <p className="text-muted fw-semibold">&nbsp;</p>
                  )}
                </Card.Title>

                <ul className="px-0 mx-0">
                  {i.benefits.map((element, idex) => (
                    <li key={idex + 10} className="list-group-item mx-0 px-0">
                      <Check className={`tablelogo text-${i.theme}`} />
                      {element}
                    </li>
                  ))}
                </ul>
                {idx === 1 ? (
                  <PayModal />
                ) : (
                  <a
                    href="mailto:mercadocripto@livecoins.com.br"
                    className={`mt-auto btn btn-lg btn-${i.theme}`}
                  >
                    {i.button}
                  </a>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="small text-muted">
Ao continuar, você concorda com os <a href="/termos" tile="Termos de Serviço">Termos de Serviço</a> do MercadoCripto.
</div>
    </Container>
  );
};

export default Assine;
