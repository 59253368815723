import React, { useState, useEffect } from "react";
import { Container, Spinner } from 'react-bootstrap';
import ExchangesDonut from "../../../charts/ExchangesDonut";
import SymbolsTable from "../../SymbolsTable";
import { getAdminResource } from "../../../auth/services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import { symbolsGroupBy } from "../../../utils/groupBy";
import { useSymbolsNow } from "../../../utils/api";

const DailySymbols = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [donutData, setDonutData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  let othersVolume = 0;
  const totalMonthQuoteVolume = props.totalMonthQuoteVolume;
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1);
  const month = new Date(yesterday).getMonth() + 1;
  const year = new Date(yesterday).getFullYear()

  const { symbolsData, isLoading, isError } = useSymbolsNow(5 * 60 * 1000); //5 min cache
  const topSeven = symbolsData.sort((a, b) => b.QuoteVolume - a.QuoteVolume).slice(0, 7).map(i => i.Symbol)

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      setIsLoaded(false);
      setError(null);
      try {
        const response = await getAdminResource(accessToken, `historicalmonthsymbolvolume?month=${month}&year=${year}&symbols=${topSeven}`);
        if (response.status < 200 || response.status >= 300) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (!ignore) {
          const monthreport = symbolsGroupBy(response.data)

          othersVolume = totalMonthQuoteVolume - monthreport.reduce((acc, current) => acc + current.quoteVolume.reduce((acc, current) => acc + current, 0), 0)
          const chartAndTableData = monthreport.map(item => {
            const sum = item.quoteVolume.reduce((acc, current) => acc + current, 0);
            const volumeSum = item.volume.reduce((acc, current) => acc + current, 0);
            const volumePercent = (sum / totalMonthQuoteVolume) * 100;
            return { Symbol: item.name, QuoteVolume: sum, Volume: volumeSum, YesterdayVolume: 0, VolumePercent: volumePercent / 100 };
          });

          setDonutData(chartAndTableData.sort((a, b) => b.QuoteVolume - a.QuoteVolume))
          setTableData(chartAndTableData.sort((a, b) => b.QuoteVolume - a.QuoteVolume).slice(0, 5))
          setIsLoaded(true);
        }
      } catch (error) {
        setError(error);
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, month, year, totalMonthQuoteVolume]);

  if (error || isError) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded || isLoading) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
      <SymbolsTable data={tableData} full={totalMonthQuoteVolume} marketshare={true}
        columnVisibility={{
          Rank: false,
          SymbolName: true,
          Symbol: false,
          Last: false,
          QuoteVolume: true,
          VolumePercent: true,
        }} />
        <ExchangesDonut data={donutData} othersVolume={othersVolume}/>
      </>
    )
  }
}

export default DailySymbols