import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { useSymbolsNow } from './utils/api.js';
import Error from './utils/Error.js';
import SymbolsTable from './components/SymbolsTable.js';
import NewsFeed from './components/NewsFeed.js'

const Symbols = () => {
  const { symbolsData, error, isLoading } = useSymbolsNow(100000) // Fetch data every 100 seconds

  if (isLoading) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else if (error) {
    return (<><Error /></>)
  } else if (!symbolsData) {
    return <Container className="text-center p-5"><br />Nenhum dado encontrado.</Container>;
  }

  const sortedCoins = symbolsData.sort((a, b) => parseFloat(b.QuoteVolume) - parseFloat(a.QuoteVolume));
  const volumeToday = symbolsData.reduce((a, e) => a + e.QuoteVolume, 0);

  const organization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "MercadoCripto",
    "description": "O MercadoCripto fornece uma análise fundamental do mercado de criptomoedas no Brasil. Alem do preço do Bitcoin e todas as altcoins do mercado brasileiro de criptomoedas, todas as corretoras são ranqueadas por volume. Acesso a gráficos, pares de negociação, e dados históricos de todas as corretoras do país.",
    "url": "https://www.mercadocripto.livecoins.com.br/",
    "logo": "https://www.mercadocripto.livecoins.com.br/mercadocripto-logo.png",
  };

  const FAQPage = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Qual o volume diário de criptomoedas negociadas no Brasil?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "O volume total de criptomoedas negociados nas últimas 24 horas apenas no Brasil é de " + volumeToday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ", somando todos os pares com BRL."
        }
      },
    ]
  }

  return (
    <>
      <Helmet>
        <title>MercadoCripto · Preço, volume e gráficos do mercado de criptomoedas brasileiro</title>
        <meta name="description" content="O MercadoCripto fornece uma análise fundamental do mercado de criptomoedas no Brasil. Alem do preço do Bitcoin e todas as altcoins do mercado brasileiro de criptomoedas, todas as corretoras são ranqueadas por volume. Acesso a gráficos, pares de negociação, e dados históricos de todas as corretoras do país." />
        <script type="application/ld+json">{JSON.stringify(organization)}</script>
        <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
      </Helmet>
      <div>
        <Container>
          <h1 className="h1 fw-semibold mt-4">Criptomoedas negociadas em BRL</h1>
          <p className="my-4 fw-semibold text-muted">As criptomoedas negociadas em real brasileiro (BRL), ranqueadas por volume negociado nas últimas 24 horas.</p>
        </Container>
      </div>
      <Container className="my-5">
        <SymbolsTable data={sortedCoins} full={volumeToday} sponsor={''} />
        <div className="d-none d-md-block">
          <NewsFeed slug={Math.random() < 0.5 ? 'criptomoedas' : 'noticias'} />
        </div>
      </Container>
    </>
  );
};

export default Symbols;