import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useSymbolsNow } from "./utils/api";
import { VariationIndicator, formatBigNumbers } from "./utils/priceFormats";
import { useTheme } from './components/Theme/ThemeContext';

const SecondaryHeader = (props) => {
    const exchanges = props.exchanges
    const { theme } = useTheme();
    const volumeToday = exchanges?.reduce((a, e) => a + e.QuoteVolume, 0);
    const volumeYesterday = exchanges?.reduce((a, e) => a + e.YesterdayVolume, 0);

    const { symbolsData, isLoading } = useSymbolsNow(1000 * 60 * 60 * 24) // Fetch data every 24 hours

    if (exchanges) {
        return (
            <div className="border-bottom d-none d-md-block " >
                <Container>
                    <ul className="list-inline small my-2 fw-semibold text-muted">
                        <li className="list-inline-item me-3">
                            Volume 24h: <span className={`text-primary${theme === 'dark' ? '-emphasis' : ''}`}> R$ {formatBigNumbers(volumeToday).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 })}</span>
                            <VariationIndicator valueToday={volumeToday} valueYesterday={volumeYesterday} />
                        </li>
                        <li className="list-inline-item me-3">
                            <Link to="/" className="text-decoration-none text-muted">
                                Corretoras: <span className={`text-primary${theme === 'dark' ? '-emphasis' : ''}`}>{exchanges.length}</span>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link to="/dominancia" className="text-decoration-none text-muted">
                                Dominância Binance: <span className={`text-primary${theme === 'dark' ? '-emphasis' : ''}`}>{exchanges.find(exchange => exchange.Name === 'Binance')?.VolumePercent.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 })}</span>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link to="/criptomoedas" className="text-decoration-none text-muted">
                                Criptomoedas: <span className={`text-primary${theme === 'dark' ? '-emphasis' : ''}`}>
                                    {
                                        (!isLoading) ? symbolsData.length ?? '...' :
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                    }
                                </span>
                            </Link>
                        </li>
                    </ul>
                </Container>
            </div>
        )
    }
}
export default SecondaryHeader
