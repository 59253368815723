import React from "react";
import { Container, Spinner, FigureCaption } from 'react-bootstrap';
import Treemap from "../../../charts/ExchangeTreeMap";
import { useExchangesNow } from "../../../utils/api";
import ExchangeTable from "../../ExchangeTable";

const DailyDashboard = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const { exchanges, isLoading, isError } = useExchangesNow();
  const [tableData, setTableData] = React.useState(null);
  const [exchangeNumber, setExchangeNumber] = React.useState(5);

  React.useEffect(() => {
    setExchangeNumber(props.isExchFive ? 5 : 30);
  }, [props.isExchFive]);

  React.useEffect(() => {
    if (exchanges) {
      setTableData(exchanges.sort((a, b) => b.VolumePercent - a.VolumePercent).slice(0, exchangeNumber));
    }
  }, [exchanges, exchangeNumber]);

  if (isError) {
    return <div>Error: {isError.message}</div>;
  } else if (isLoading) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        {tableData && <ExchangeTable data={tableData} fees={props.fees} visibleColumns={["Name", "QuoteVolume", "VolumePercent", "Fees", "Lucro"]} />}
        <FigureCaption>
          *   As taxas e lucros são calculados pela soma das taxas <em className="fw-semibold text-primary">Taker</em> e <em className="fw-semibold text-primary">Maker</em>.
        </FigureCaption>
        <Treemap data={exchanges} />
      </>
    )
  }
}

export default DailyDashboard;
