import React from "react";
import { ListGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import logo from './logos/mercadocripto-logo.png';
import AdReports from '../utils/AdReports'
import { useTheme } from './Theme/ThemeContext';

const Footer = () => {
    const { theme } = useTheme();
    
    return (
        <>
            <AdReports />
            <footer className="bg-transparent border-0">
                <ListGroup id="footer" horizontal={'md'} className="py-3 justify-content-evenly text-center fw-semibold">
                    <ListGroup.Item className="footerlink my-1 border-0"><img src={logo} alt="mercadocripto" title="mercadocripto" width="170" height="25.5" className={theme === 'dark' ? '' : 'invertcolor'}/></ListGroup.Item>
                    <ListGroup.Item as={NavLink} className="footerlink my-1 border-0" to="/contato">Contato</ListGroup.Item>
                    <ListGroup.Item as={NavLink} className="footerlink my-1 border-0" to="/metodologia">Metodologia</ListGroup.Item>
                    <ListGroup.Item as={NavLink} className="footerlink mt-1 border-0" to="/widget">Widget</ListGroup.Item>
                </ListGroup>
                <div className="mb-5 d-xs-block d-md-none"></div>
            </footer>
        </>
    );
}
export default Footer