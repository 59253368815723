import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ReactComponent as Check } from "../components/svg/check2.svg";

const AdDiv = (props) => {

  return (
    <div className='bg-body-tertiary py-5 mt-3 border-bottom bg-gradient'>
      <Container>
        <div className="d-md-flex flex-sm-row align-items-center">
          <div className="p-2 flex-fill fw-semibold">
            <h3 className="fw-semibold h2">Fique por dentro do mercado de criptomoedas</h3>
            <p className="my-4">Torna-se um assinante do MercadoCripto e tenha:</p>
            <p className="text-muted"><Check className="tablelogo text-success" /> Acesso completo a relatórios e gráficos mensais e anuais de volume.</p>
            <p className="text-muted"><Check className="tablelogo text-success" /> Informações detalhadas sobre a dominância do mercado, com gráficos atualizados.</p>
            <p className="text-muted"><Check className="tablelogo text-success" /> Download dos dados no formato <code>.csv</code>, <code>.json</code> e Excel</p>
          </div>
          <div className="p-2 flex-fill text-center text-md-end">
            <Link to='/assine' title="Assine Nossos Serviços" className="btn btn-lg btn-primary text-white fw-medium py-3 px-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16" className="me-2">
                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-2 11.5v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5m-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z" />
              </svg>
              Conheça nossas soluções</Link>
          </div>
        </div>

      </Container>
    </div>
  );
}

export default AdDiv;
