import React from "react";
import SymbolMarket from "./SymbolMarket";
import Symbols from './Symbols.js';
import Metodologia from './Metodologia';
import Contato from './Contato';
import Graficos from './Graficos';
import Dominancia from './Dominancia';
import Widget from './Widget';
import Assine from './Assine';
import Terms from './Terms';
import { useAuth0 } from "@auth0/auth0-react";
import ExchangeSummary from './ExchangeSummary';
import Dca from './Dcacripto';
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import NavBar from './components/NavBar';
import SecondaryHeader from "./SecondaryHeader.js";
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import LivecoinsHeader from "./components/Livecoins/LivecoinsHeader";
import Exchanges from './Exchanges';
import ScrollToTopNavigation from "./components/ScrollToTopNavigation";
import usePageTracking from "./utils/withTracker";
import ScrollToTop from "./components/ScrollToTop";
import { AuthenticationGuard } from "./auth/authentication-guard";
import { CallbackPage } from "./auth/callback-page";
import { ProfilePage } from "./auth/profile-page";
import { ProtectedPage } from "./auth/protected-page";
import { AdminPage } from "./auth/admin-page";
import { DashboardPage } from "./auth/dashboard-page";
import RelatoriosClosed from "./components/restricted/Relatorios-closed";
import { useExchangesNow } from "./utils/api.js";
import { HelmetProvider } from 'react-helmet-async';

function App() {
    const { isLoading: isLoadingAuth } = useAuth0();
    const { exchanges, error, isLoading } = useExchangesNow();
    usePageTracking();

    if (isLoadingAuth) {
        return (
            <div className="container">
                <div className="text-center mx-auto mt-5 spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }


    return (
        <>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/" element={<Layout exchanges={exchanges} isLoading={isLoading} error={error} />}>
                    <Route index element={<Exchanges exchanges={exchanges} />} />
                    <Route path="/criptomoedas" element={<Symbols />} />
                    <Route path="/dominancia" element={<Dominancia exchanges={exchanges} />} />
                    <Route path="/metodologia" element={<Metodologia />} />
                    <Route path="/contato" element={<Contato />} />
                    <Route path="/widget" element={<Widget />} />
                    <Route path="/relatorios" element={<RelatoriosClosed />} />
                    <Route path="/graficos" element={<Graficos />} />
                    <Route path="/termos" element={<Terms />} />
                    <Route path="/DCA" element={<Dca />} />
                    <Route
                        path="/profile"
                        element={<AuthenticationGuard component={ProfilePage} />}
                    />
                    <Route path="/assine"
                        element={<AuthenticationGuard component={Assine} />}
                    />
                    <Route path="/protected"
                        element={<AuthenticationGuard component={ProtectedPage} />}
                    />
                    <Route
                        path="/dadosmensais"
                        element={<AuthenticationGuard component={AdminPage} />}
                    />
                    <Route
                        path="/dashboard"
                        element={<AuthenticationGuard component={DashboardPage} />}
                    />
                    <Route path="/callback" element={<CallbackPage />} />

                    <Route path="/corretoras" element={<Outlet />}>
                        <Route index element={<Navigate to="/" />} />
                        <Route path='*' element={<NotFound />} />
                        <Route path=":exchange" element={<ExchangeSummary />} />
                    </Route>
                    <Route path="/:Symbol" element={<SymbolMarket />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

function Layout(props) {
    return (
        <>
            <HelmetProvider>
                <LivecoinsHeader />
                <NavBar />
                <SecondaryHeader exchanges={props.exchanges} />
                <ScrollToTopNavigation />
                <div className="mainpage">
                    {props.error ? (
                        <div className="container">
                            <div className="text-center mt-5 text-danger">
                                Error: {props.error.message}
                            </div>
                        </div>
                    ) : props.isLoading ? (
                        <div className="container">
                            <div className="text-center mx-auto mt-5 spinner-border text-primary" role="status">
                                <span className="visually-hidden">Carregando...</span>
                            </div>
                        </div>
                    ) : (
                        <Outlet />
                    )}
                </div>
                <ScrollToTop />
                <Footer />
            </HelmetProvider>
        </>
    );
}


export default App;

