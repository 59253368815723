import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import ProgressStackedBar from "./charts/ProgressStackedBar.js"
import StackedAreaChart from "./charts/StackedAreaChart.js";
import ChartControl from "./charts/ChartControl.js";
import Newsfeed from "./components/NewsFeed.js";

const Dominancia = (props) => {
    const [frequency, setFrequency] = useState(24)
    const [intervalsate, setIntervalsate] = useState('week')
    const [active, setActive] = useState('15D')

    const buttons = [
        { id: "15D", label: "15D", frequency: 24, intervalsate: 'week' },
        { id: "1M", label: "1M", frequency: 24, intervalsate: 'month' },
        { id: "6M", label: "6M", frequency: 24, intervalsate: 'sixmonths' },
        { id: "1A", label: "1A", frequency: 48, intervalsate: 'year' },
        { id: "MAX", label: "MAX", frequency: 144, intervalsate: 'max' },
    ];

    const exchanges = props.exchanges

    useEffect(() => {
        let sortedData = [...exchanges].sort((a, b) => b.QuoteVolume - a.QuoteVolume);

        if (sortedData.length > 6) {
            let others = {
                "Name": "Outros",
                "Nsymbols": "Outros",
                "QuoteVolume": 0,
                "VolumePercent": 0,
                "YesterdayVolume": 0
            };

            for (let i = 6; i < sortedData.length; i++) {
                others.QuoteVolume += sortedData[i].QuoteVolume;
                others.Volume += sortedData[i].Volume;
                others.Last = (others.Last * (i - 6) + sortedData[i].Last) / (i - 5);
            }

            sortedData = sortedData.slice(0, 6);
            sortedData.push(others);
        }
    })

    const BreadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Criptomoedas",
                "item": "https://mercadocripto.livecoins.com.br/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Dominancia",
                "item": "https://mercadocripto.livecoins.com.br/dominancia",
            }
        ]
    };

    const FAQPage = {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "Qual a maior corretora de criptomoeda do Brasil em volume?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A maior corretora de criptomoedas que opera no Brasil é a " + exchanges[0].Exchange + ". Nessa corretora foram negociados um total de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + " nas últimas 24 horas, somando apenas todos os pares com BRL."
                }
            },
            {
                "@type": "Question",
                "name": "Quantas corretoras de criptomoedas aceitam PIX ou TED de real brasileiro (BRL)?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Existem hoje um total de " + exchanges.lenght + " corretoras de criptomoedas operando no Brasil, e todas essas aceitam PIX ou TED para depósitos e retiradas."
                }
            },
            {
                "@type": "Question",
                "name": "Qual o volume diário de criptomoedas negociadas no Brasil?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "O volume total de criptomoedas negociados nas últimas 24 horas apenas no Brasil é de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ", somando todos os pares com BRL."
                }
            },
        ]
    }

    return (
        <>
            <Helmet>
                <title>Dominância do Mercado de Criptomoedas Brasileiro · MercadoCripto</title>
                <meta name="description" content="Ranking de corretoras brasileiras de criptomoedas, com detalhes do volume diário, o preço de cada par negociado. Acesso a gráficos, diferenças de preço (ágio/spread), e dados históricos de todas as corretoras do Brasil." />
                <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
                <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
            </Helmet>
            <Container>
                <h1 className="h2 fw-semibold mt-4">Dominância do Mercado</h1>
                <p className="my-4 fw-normal">Dominância do mercado é uma métrica utilizada para medir a participação de mercado relativa de todas as corretoras no mercado brasileiro de criptomoedas. Ela representa a porcentagem do volume de todas as moedas negociadas <strong className='text-primary'>em BRL</strong> em todas as corretoras nacionais.</p>
                <p>Nossos dados estão disponíveis a partir de agosto de 2021, quando o MercadoCripto foi inaugurado. Contudo, na versão gratuita apenas os dados dos últimos 15 dias estão disponíveis.</p>
                <Row>
                    <Col md={6} >
                        <ProgressStackedBar data={exchanges} />
                    </Col>
                    <Col lg={12} xl={8}>
                    </Col>
                </Row>
                <ChartControl
                    title={'Gráfico de Dominância do Mercado'}
                    frequency={frequency}
                    setFrequency={setFrequency}
                    interval={intervalsate}
                    setIntervalsate={setIntervalsate}
                    active={active}
                    setActive={setActive}
                    buttons={buttons}
                />
                <StackedAreaChart frequency={frequency} interval={intervalsate} nowdata={exchanges} />

                <div className="pt-4">
                    <Newsfeed slug="Binance"/>
                </div>
            </Container>
        </>
    );
}

export default Dominancia;