import { ProgressBar, Col, Row } from 'react-bootstrap';
import { ReactComponent as Caret } from '../components/caret-up-fill.svg';
import { useTheme } from '../components/Theme/ThemeContext';

function StackedExample(props) {
    let data = props.data;
  const { theme } = useTheme();

    const totalYesterdayVolume = data.reduce((acc, current) => acc + current.YesterdayVolume, 0);
    data.forEach((data) => {
        data.YesterdayVolumePercent = (data.YesterdayVolume / totalYesterdayVolume);
    });
    data = [...data].sort((a, b) => b.VolumePercent - a.VolumePercent);

    // Calculate the sum of all other exchanges
    const othersVolumePercent = data.slice(3).reduce((acc, exchange) => acc + exchange.VolumePercent, 0);
    const othersYesterdayVolumePercent = data.slice(3).reduce((acc, exchange) => acc + exchange.YesterdayVolumePercent, 0);

    // Create a new array with the "Outros" exchange
    const exchanges = [
        ...data.slice(0, 3),
        { Name: "Outros", VolumePercent: othersVolumePercent, YesterdayVolumePercent: othersYesterdayVolumePercent },
    ];

    const colors = [
        "binance",
        "secondary",
        "success",
        theme === 'dark' ? 'light' :"dark-subtle" ,
        "purple",
        "info",
    ];

    // Create the progress bar elements
    const progressBarElements = exchanges.map((exchange, index) => (
        <ProgressBar
            key={index}
            max={100}
            now={exchange.VolumePercent * 100}
            variant={colors[index]}
        />
    ));

    // Create the legend elements
    const legendElements = exchanges.map((exchange, index) => (
        <Col key={index}>
            <div key={index} className="d-flex align-items-center mb-2">
                <div className={`rounded-circle bg-${colors[index]} me-2`} style={{ width: "8px", height: "8px" }}></div>
                <span className='text-center text-muted fw-semibold small'>{exchange.Name}</span>
            </div>
            <h4 className='fw-bold'>
                {exchange.VolumePercent.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 })}
            </h4>
            {!isNaN(parseFloat(exchange.YesterdayVolumePercent))  && (
                <p className={`fw-bold ${exchange.VolumePercent > exchange.YesterdayVolumePercent ? 'text-success' : 'text-danger'}`}>
                    {(() => {
                        const change = exchange.VolumePercent - exchange.YesterdayVolumePercent;
                        const isPositive = change > 0;
                        const formattedChange = Math.abs(change).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 });

                        return (
                            <span className={`text-nowrap ${isPositive ? 'text-success' : 'text-danger'}`}>
                                <Caret className={isPositive ? 'icontable' : 'icon-rotated'} />
                                {formattedChange}
                            </span>
                        );
                    })()}
                </p>
            )}
        </Col>
    ));

    return (
        <>
            <h6 className='fw-semibold my-3'>Dominância do Mercado</h6>
            <Row className='mb-3'>
                {legendElements}
            </Row>
            <ProgressBar style={{ height: "6px" }}>
                {progressBarElements}
            </ProgressBar>
        </>
    )
}

export default StackedExample;
