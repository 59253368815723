import { useContext } from 'react';
import { Col, Row, Accordion, AccordionContext, Form, useAccordionButton } from 'react-bootstrap';
import Treemap from "../charts/ExchangeTreeMap";
import Newsfeed from './NewsFeed';

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Form>
      <Form.Switch
        type="switch"
        className="d-flex align-items-center custom-switch"
        id={`switch-${eventKey}`}
        label={
          <div className={`d-flex align-items-center ms-2 fw-semibold ${isCurrentEventKey ? 'text-primary-emphasis' : ''}`}>
            {children}
          </div>
        }
        checked={isCurrentEventKey}
        onChange={decoratedOnClick}
      />
    </Form>
  );
}

function MainPageAccordion(props) {
  return (
    <Accordion>
      <div className="d-flex justify-content-end">
        <ContextAwareToggle eventKey="0">Gráficos</ContextAwareToggle>
      </div>
      <Accordion.Collapse eventKey="0">
        <Row>
          <Col>
            <Treemap data={props.exchanges} />
          </Col>
          <Col>
          </Col>
        </Row>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default MainPageAccordion;
