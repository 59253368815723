import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./login-button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { checkRoleClaim } from "../../auth/services/message.service";

export const NavBarButtons = () => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [hasCryptoComDashboardRole, setHasCryptoComDashboardRole] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    const checkRole = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        const hasRole = await checkRoleClaim(accessToken, 'read:cryptodotcomdashboard');
        setHasCryptoComDashboardRole(hasRole);
      }
    };
    checkRole();
  }, [isAuthenticated, getAccessTokenSilently]);

  const dropdownItems = [
    {
      to: "/assine",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22" strokeWidth={1.5} stroke="currentColor" className="me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
        </svg>
      ),
      label: 'Planos'
    },
    {
      to: "/dadosmensais",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22" strokeWidth={1.5} stroke="currentColor" className="me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
        </svg>
      ),
      label: 'Dados Mensais'
    },
    {
      to: "/dominancia",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor" stroke="currentColor"  className="me-2">
          <path d="M120-160v-520l160 120 200-280 200 160h160v520H120Zm200-120 160-220 280 218v-318H652L496-725 298-447l-98-73v144l120 96Z" /></svg>
      ),
      label: 'Dominância de Mercado'
    },
    ...(hasCryptoComDashboardRole ? [{
      to: "/dashboard",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 -960 960 960" width="22px" stroke="currentColor" className="me-2">
          <path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
        </svg>
      ),
      label: 'Dashboard'
    }] : []),
    {
      to: "#",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22" strokeWidth={1.5} stroke="currentColor" className="me-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
        </svg>
      ),
      label: 'Sair',
      onClick: handleLogout
    }
  ];


  return (
    <div>
      {!isAuthenticated && <LoginButton />}

      {isAuthenticated && (
        <>

          <DropdownButton id="dropdown-nav-auth" title={<Image src={user.picture} alt="Profile" roundedCircle width={30} height={30} />}>
            <div className="border-0 mx-3">
              <Link to="/profile" className="d-flex align-items-center mb-2 pb-3 pt-2 mx-2 text-decoration-none border-bottom">
                <Image src={user.picture} alt="Profile" roundedCircle width={40} height={40} className="me-2" />
                <div>
                  <div className="fw-semibold text-body">{user.nickname}</div>
                  <div className="small text-muted">{user.email}</div>
                </div>
              </Link>

              {dropdownItems.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  as={item.to === "#" ? "button" : Link}
                  to={item.to}
                  className="fw-semibold px-2 text-muted rounded"
                  onClick={item.onClick}
                >
                  {item.icon}
                  {item.label}
                </Dropdown.Item>
              ))}
            </div>
          </DropdownButton>
        </>
      )}
    </div>
  );
};
