import React, { useState, useEffect } from "react";
import MonthReport from "../../charts/MonthReport";
import { Container, Row, Form, Col, Spinner } from 'react-bootstrap';
import { getAdminResource } from "../../auth/services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import Relatorios from '../Relatorios';
import coinlogo from "../logos/coinlogo.svg";
import { memo } from 'react';
import { useCoinList } from "../../utils/api";

const RelatoriosClosed = (props) => {
  document.title = "Dados mensais do volume negociado por cada corretora de criptomoeda no Brasil · MercadoCripto";
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1)

  const [message, setMessage] = useState([]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { data:coinList, error } = useCoinList();

  const [selectedCrypto, setSelectedCrypto] = useState('?symbol=BTCBRL');
  const [selectedMonth, setSelectedMonth] = useState(new Date(yesterday).getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date(yesterday).getFullYear());
  const [allYears, setallYears] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const MemoizedMonthReport = memo(MonthReport, (prevProps, nextProps) => {
    return prevProps.year === nextProps.year;
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
    if (isSwitchOn) {
      setSelectedYear(new Date(yesterday).getFullYear() - 1);
    } else {
      setSelectedYear(new Date(yesterday).getFullYear());
    }
  };

  function handleSelectChange(event) {
    setSelectedCrypto(event.target.value);
  }
  function handleSelectChangeMonth(event) {
    setSelectedMonth(event.target.value);
  }
  function handleSelectChangeYear(event) {
    setSelectedYear(event.target.value);
  }

  useEffect(() => {
    if (isSwitchOn) {
      const firstYear = allYears[allYears.length - 2]; // get the first year in the dropdown
      setSelectedYear(firstYear);
    }
  }, [isSwitchOn, allYears]);

  useEffect(() => {
    const getPlan = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await getAdminResource(accessToken, 'profilestatus');

          if (!!data && data.message === "yes") {
            setMessage("Plano Pro");
          } else {
            setMessage("Gratuito");
          }
          if (error) {
            setMessage("Gratuito");
          }
        } catch (err) {
          setMessage("Gratuito");
        }
      } else {
        setMessage("Gratuito");
      }
    };
    getPlan();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    let startYear = 2021;
    const currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setallYears(years);
  }, [])

  // If user is not authenticated, show Relatorios component
  if (!isAuthenticated) {
    return <Relatorios />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!coinList) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else if (message === "Gratuito") {
    return <Relatorios />
  } else {
    return (
      <>
        <title>Dados mensais do volume negociado por cada corretora de criptomoeda no Brasil · MercadoCripto</title>
        <meta name="description" content="Relatórios mensais do volume negociado por cada corretoras de criptomoedas no Brasil, separado por par, moeda e volume. Ranking de corretoras brasileiras de criptomoedas e dados históricos de todas as corretoras do Brasil." />
        <div className="py-4">
          <Container>
            <Row xs="auto">
              <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"> <span className={!isSwitchOn ? 'text-body' : 'text-muted'}> Dados Mensais</span></h1></Col>
              <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="anual-mensal" aria-label="anual-mensal" checked={isSwitchOn} onChange={onSwitchAction} className="custom-control-input" /></Col>
              <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"><span className={!isSwitchOn ? 'text-muted' : 'text-body'}>Dados Anuais</span></h1></Col>
            </Row>
            <p className="fw-normal lead">Abaixo você encontra dados consolidados do volume negociado no mês de cada criptomoeda.<br /> Selecione a criptomoeda e o mês do seu interesse para gerar o gráfico e relatório.</p>

            <Row className="align-items-center">
              <Col sm={4} lg={3} className="my-1">
                <Form.Group controlId="cryptocurrencyselect">
                  <Form.Label>Criptomoeda</Form.Label>
                  <Form.Select value={selectedCrypto} onChange={handleSelectChange}>
                    <option value="all?symbol=BRLBRL" key="todas">Todas</option>
                    {coinList.map((data, index) => {
                      if (data) {
                        return (
                          <option value={"?symbol=" + data.Symbol} key={data.Symbol}>{(data.SymbolName !== null ? (data.SymbolName + " - ") : '') + data.Symbol.slice(0, -3)}</option>
                        )
                      }
                      return null
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              {!isSwitchOn ?
                <>
                  <Col sm={4} lg={3} className="my-1">
                    <Form.Group controlId="monthselect">
                      <Form.Label>Mês</Form.Label>
                      <Form.Select value={selectedMonth} onChange={handleSelectChangeMonth}>
                        <option value="1" key="1">Janeiro</option>
                        <option value="2" key="2">Fevereiro</option>
                        <option value="3" key="3">Março</option>
                        <option value="4" key="4">Abril</option>
                        <option value="5" key="5">Maio</option>
                        <option value="6" key="6">Junho</option>
                        <option value="7" key="7">Julho</option>
                        <option value="8" key="8">Agosto</option>
                        <option value="9" key="9">Setembro</option>
                        <option value="10" key="10">Outubro</option>
                        <option value="11" key="11">Novembro</option>
                        <option value="12" key="12">Dezembro</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </>
                :
                ''
              }
              <Col sm={4} lg={3} className="my-1">
                <Form.Group controlId="yearselect">
                  <Form.Label>Ano</Form.Label>
                  <Form.Select value={selectedYear} onChange={handleSelectChangeYear}>
                    {(() => {
                      let yearsToDisplay = [...allYears];
                      if (isSwitchOn) {
                        yearsToDisplay.pop();
                        yearsToDisplay.push(0); 
                      }
                      return yearsToDisplay.map((data, index) => {
                        return (
                          <option value={data} key={data}>
                            {data === 0 ? "12 meses" : data}
                          </option>
                        );
                      });
                    })()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={2} className="text-center">
                <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                  <title id="title">{selectedCrypto}</title>
                  <use href={coinlogo + "#" + (selectedCrypto.split('=').pop().slice(0, -3)).toLowerCase() + "-logo"}></use>
                </svg>
                <div className="h5 align-middle mx-2">{selectedCrypto.split('=').pop() === "BRLBRL" ? 'Todas as criptomoedas' : selectedCrypto.split('=').pop()}</div>
                <p className="h5 text-body monthcase">{isSwitchOn ? (parseInt(selectedYear) === 0 ? 'Últimos 12 meses' : selectedYear) : selectedMonth + '/' + selectedYear}</p>
              </Col>
            </Row>
          </Container>
        </div>

        {selectedCrypto.length !== 0 && (
          <MemoizedMonthReport
            symbol={selectedCrypto}
            month={selectedMonth}
            year={selectedYear}
            type={isSwitchOn ? 'year' : 'month'}
          />
        )}
      </>
    )
  }
}

export default RelatoriosClosed