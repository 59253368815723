import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function PayModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="primary"
        className="mt-auto btn btn-lg btn-warning"
        onClick={handleShow}
      >
        Assine
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Torne-se um assinante</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>          Após fazer seu pagamento, você receberá um e-mail com instruções para
            liberação do seu acesso em algumas horas.</p>

          <p> Pague no cartão de crédito com Stripe.
          </p>

          <div className="d-grid gap-2 my-4">
            <Button target="_blank" rel="noopener noreferrer" href="https://buy.stripe.com/00g6rQ6eyc7w5Z6dQQ" variant="gray" className="text-white" size="lg">
              <span className='me-2'>
              </span>Plano Mensal
            </Button>{" "}
            <Button target="_blank" rel="noopener noreferrer" href="https://buy.stripe.com/bIYdUifP86Nc4V24gh" variant="primary" size="lg">
              <span className='me-2'>
              </span>Plano Anual
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="small text-muted">
          Ao continuar, você concorda com os{" "}
          <a href="/termos" tile="Termos de Serviço">
            Termos de Serviço
          </a>{" "}
          do MercadoCripto.
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PayModal;
