import React, { useState } from "react";
import { Container, Row, Form, Col } from 'react-bootstrap';
import MonthDashboard from './MonthDashboard';
import DailyDashboard from './DailyDashboard';
import DailySymbols from './DailySymbols';
import MonthlySymbols from './MonthlySymbols';
import fees from '../../../data/fees.json';

const RelatoriosClosed = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isExchFive, setIsExchFive] = useState(true);
  const [totalMonthVolume, setTotalMonthVolume] = useState();

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const onSwitchExchangeNumber = () => {
    setIsExchFive(!isExchFive);
  };

  return (
    <>
      <title>Dashboard Plano Empresarial · MercadoCripto</title>
      <meta name="description" content="Relatórios mensais do volume negociado por cada corretoras de criptomoedas no Brasil, separado por par, moeda e volume. Ranking de corretoras brasileiras de criptomoedas e dados históricos de todas as corretoras do Brasil." />
      <div className="py-4">
        <Container>
          <Row xs="auto mb-1">
            <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"> <span className={!isSwitchOn ? 'text-body' : 'text-muted'}>Últimas 24 horas</span></h1></Col>
            <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="anual-mensal" aria-label="anual-mensal" checked={isSwitchOn} onChange={onSwitchAction} className="custom-control-input" /></Col>
            <Col xs={3} lg="auto"><h1 className="h2 font-weight-bolder"><span className={!isSwitchOn ? 'text-muted' : 'text-body'}>Mês corrente</span></h1></Col>
          </Row>
          <p className="lead fw-normal">O primeiro acesso do dia pode demorar até 10 minutos para ser processado uma vez solicitado. Favor aguardar e recarregar a página.</p>

          <Row xs="auto">
            <Col xs={3} lg="auto"><h5 className="font-weight-bolder"> <span className={isExchFive ? 'text-body' : 'text-muted'}>5 corretoras</span></h5></Col>
            <Col xs={3} lg="auto" className="text-center my-auto pb-2"><Form.Check type="switch" id="exch-five" aria-label="exch-five" checked={!isExchFive} onChange={onSwitchExchangeNumber} className="custom-control-input" /></Col>
            <Col xs={3} lg="auto"><h5 className="font-weight-bolder"><span className={isExchFive ? 'text-muted' : 'text-body'}>Todas as corretoras</span></h5></Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <h3>Corretoras</h3>
            <p>Volume de todas as criptomoedas negociadas contra o BRL por corretora no período.</p>
            {!isSwitchOn ?
              <DailyDashboard fees={fees} isExchFive={isExchFive} />
              :
              <MonthDashboard fees={fees} isExchFive={isExchFive} setTotalMonthVolume={setTotalMonthVolume}/>
            }
          </Col>
          <Col>
            <h3>Criptomoedas</h3>
            <p>Volume das criptomoedas negociadas em BRL de todas as corretoras no período.</p>
            {!isSwitchOn ?
              <DailySymbols />
              :
              <MonthlySymbols totalMonthQuoteVolume={totalMonthVolume}/>
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RelatoriosClosed