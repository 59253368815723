import React, { Suspense, useState, useEffect } from 'react';
import { Placeholder } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import logo from '../components/logos/mercadocripto-logo.png';
import { useTheme } from '../components/Theme/ThemeContext';

const Chart = React.lazy(() => import('react-apexcharts'));

const TreeMap = (props) => {
  const navigate = useNavigate()
  const [series, setSeries] = useState([{ name: 'corretoras', data: [] }]);
  const { theme } = useTheme();
  const [options, setOptions] = useState({
    noData: {
      text: "Carregando...",
      verticalAlign: "middle",
      align: 'center',
    },
    chart: {
      animations: {
        enabled: true,
      },
      type: 'treemap',
      background: '#f8f9fa'
    },
  });
  const updateCharts = () => {
    var chart = props.data.map(x => ({
      'x': x.Name,
      'y': x.QuoteVolume,
      'z': x.VolumePercent,
      'fillColor': x.YesterdayVolume === 0 ? '#2f78e2' : (x.QuoteVolume - x.YesterdayVolume < 0) ? '#CD363A' : '#00a83e'
    }));
    setSeries([{ data: chart, name: 'corretoras' }]);
    setOptions({
      chart: {
        height: '100%',
        type: 'treemap',
        background: 'transparent',
        events: {
          click(event, chartContext, config) {
            if (config.config.series[config.seriesIndex])
              navigate("/corretoras/" + config.config.series[config.seriesIndex].data[config.dataPointIndex].x)
          },
        },
        toolbar: {
          show: false,
          export: {
            csv: {
              filename: "mercadocripto-corretoras" + new Date().toLocaleString('pt-BR', { month: 'numeric', year: 'numeric', day: "numeric" }).replaceAll("/", ""),
              columnDelimiter: ';',
              headerCategory: 'Data',
              dateFormatter(timestamp) {
                return new Date(timestamp).toLocaleDateString()
              }
            },
            svg: {
              filename: "mercadocripto-corretoras" + new Date().toLocaleString('pt-BR', { month: 'numeric', year: 'numeric', day: "numeric" }).replaceAll("/", ""),
            },
            png: {
              filename: "mercadocripto-corretoras" + new Date().toLocaleString('pt-BR', { month: 'numeric', year: 'numeric', day: "numeric" }).replaceAll("/", ""),
            }
          }
        }
      },
      stroke: {
        colors: [theme === 'dark' ? '#262626' : 'white'],
        width: 2,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '70px',
          fontFamily: "Roboto,Helvetica, Arial, sans-serif",
          fontWeight: 600
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const zValue = w.config.series[seriesIndex].data[dataPointIndex]?.z;
          return zValue ? [value, zValue.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })] : value;
        }
      },
      tooltip: {
        enabled: true,
        marker: {
          show: true
        },
        y: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
        },
        z: {
          show: false,
          formatter: function (value) {
            return value.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })
          },
          title: ''
        },
      }
    })
  }

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      theme: {
        mode: theme,
      },
    }));
  }, [theme]);

  useEffect(() => {
    let timer1 = setTimeout(() => updateCharts(), 10);
    return () => { clearTimeout(timer1); };
  })

  if (series[0] === undefined) {
    <span className="text-muted">Erro</span>
  } else {
    return (
      <Suspense fallback={<Placeholder.Button animation="glow" variant="light" style={{ maxHeight: "80vh", maxWidth: "100vw" }} aria-hidden="true" />}>
        <div className="position-relative">
          <div className="rounded ms-2 ratio ratio-16x9">
            <Chart
              options={options}
              series={series}
              type="treemap"
              height="96%"
              width="98%"
            />
          </div>
          <img src={logo} alt="MercadoCripto" className="position-absolute translate-middle-x opacity-75" style={{ width: '20%', left: '15%', bottom: '5%' }} />
        </div>
      </Suspense>
    );
  }
}
export default TreeMap;