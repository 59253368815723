import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import ExchangesDonut from "../../../charts/ExchangesDonut";
import { useSymbolsNow } from "../../../utils/api";
import SymbolsTable from "../../SymbolsTable";

const DailySymbols = (props) => {
  document.title = "Dashboard Plano Empresarial · MercadoCripto";

  const { symbolsData, isLoading, isError } = useSymbolsNow(5 * 60 * 1000); //5 min cache
  if (isError) {
    return (<div>Ocorreu um erro de carregamento.</div>);
  }

  if (isLoading) {
    return (
      <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>
    )
  }

  const totalVolume = symbolsData.reduce((a, e) => a + e.QuoteVolume, 0);
  const donutData = symbolsData.sort((a, b) => b.QuoteVolume - a.QuoteVolume);
  const tableData = donutData.slice(0, 5);

  return (
    <>
      <SymbolsTable data={tableData} full={totalVolume} marketshare={true}
        columnVisibility={{
          Rank:false,
          SymbolName: true,
          Symbol: false,
          Last: false,
          QuoteVolume: true,
          VolumePercent: true,
        }} />
      <ExchangesDonut data={donutData} />
    </>
  )
}

export default DailySymbols