import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Breadcrumb, Badge } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import SymbolsTable from './components/SymbolsTable.js'
import NotFound from "./components/NotFound";
import ExchangesDonut from "./charts/ExchangesDonut";
import exchangelogo from "./components/logos/exchangelogo.svg";
import { Helmet } from "react-helmet-async";
import { useExchangesNow } from "./utils/api.js";
import trustscore from "./data/trustscore.json"
import { VariationIndicator } from "./utils/priceFormats.js";
import useSWR, { mutate } from "swr";
import fees from './data/fees.json';
import { fetcher } from "./utils/api.js";
import RadialBarChart from "./charts/RadialBarChart.js";

const ExchangeSummary = (props) => {
  const thisExchange = useParams().exchange
  const [volumeToday, setVolumeToday] = useState(0)
  const [volumeYesterday, setVolumeYesterday] = useState(0)
  const [exchFees, setExchFees] = useState(0)

  const location = useLocation()

  useEffect(() => {
    const currentExchange = fees.find(i => i.nome === thisExchange);
    if (currentExchange) {
      setExchFees({ maker: currentExchange.maker, taker: currentExchange.taker });
    }
  }, [thisExchange]);

  const { data: exchange, error, isLoading: isLoadingThisExch } = useSWR(`${process.env.REACT_APP_API_URL}/v1/ticker/exchanges/${thisExchange}`, fetcher, {
    refreshInterval: 1000 * 60 * 2, // 2 min cache
    retryCount: 5,
    revalidateOnFocus: true,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404) return
      if (retryCount >= 5) return
      setTimeout(() => revalidate({ retryCount }), 10000) // Retry after 10 seconds.
    }
  })

  // Re-fetch data when location changes
  useEffect(() => {
    if (location.pathname) {
      mutate(`${process.env.REACT_APP_API_URL}/v1/ticker/exchanges/${thisExchange}`);
    }
  }, [thisExchange, location.pathname]);


  const { exchanges, isLoading } = useExchangesNow();

  const trustScore = trustscore.find(i => i.name === thisExchange)?.trustscore;
  const nacional = trustscore.find(i => i.name === thisExchange)?.nacional;

  useEffect(() => {
    if (exchanges) {
      setVolumeToday(exchanges.find((i) => i.Name === thisExchange)?.QuoteVolume)
      setVolumeYesterday(exchanges.find((i) => i.Name === thisExchange)?.YesterdayVolume)
    }
  }, [thisExchange, location.pathname, exchanges]);

  if (isLoadingThisExch || isLoading) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else if (error) {
    return (<><NotFound /></>)
  } else {

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Corretoras",
          "item": "https://mercadocripto.livecoins.com.br/corretoras",
        }, {
          "@type": "ListItem",
          "position": 3,
          "item": "https://mercadocripto.livecoins.com.br/corretoras" + thisExchange,
          "name": thisExchange
        }]
    };

    const hasProofReserves = trustscore.some(i => i.name === thisExchange && i.reserve)
    const listAtributes = [
      { label: "Nº de pares BRL", value: exchange.length },
      {
        label: "Prova de Reservas", value: hasProofReserves ? (
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor" className="text-success">
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor" className="text-danger">
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        ),
      },
      { label: "Taxa maker", value: `${exchFees.maker}%` },
      { label: "Taxa taker", value: `${exchFees.taker}%` },
    ]

    if (exchange) {
      return (
        <>
          <Helmet>
            <title>{thisExchange + " - Dados de volume e preço das criptomoedas negociadas em real brasileiro na corretora · MercadoCripto"}</title>
            <meta name="description" content={"Dados de volume e de preço das criptomoedas negociadas na " + thisExchange + " em real brasileiro. O volume detalhado por par negociado em tempo real para todas as  criptomoedas. Acesso a gráficos e dados históricos."} />
            <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
          </Helmet>
          <div className="mb-5">
            <Container>
              <Breadcrumb className="py-2 mb-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/corretoras" }}>
                  Corretoras
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{thisExchange}</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="h1 fw-medium text-nowrap">
                <svg className="exchangelogo me-3" role="img" aria-labelledby="title">
                  <title id="title">{thisExchange}</title>
                  <use href={exchangelogo + "#" + (thisExchange.toLowerCase())}></use>
                </svg>
                <span className="align-middle fw-semibold">{thisExchange} </span>
                <span className="h6 text-wrap">
                  <Badge pill bg="gray" className="h6 align-middle" title="Ranking MercadoCripto">
                    #{!isLoading && exchanges.find((i) => i.Name === thisExchange)?.Rank}
                  </Badge>
                  <Badge pill bg={nacional === true ? 'success' : 'secondary'} className="h6 align-middle mx-1" title={nacional === true ? 'Nacional' : 'Internacional'}>
                  {nacional === true ? 'Nacional' : 'Internacional'}
                  </Badge>
                </span>
              </h1>
              <Row className="pe-0">
                <Col>
                  <p className="fw-semibold h3 mt-4 text-body">{volumeToday?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                  <h3 className="h6 text-muted fw-semibold">Volume 24h (pares BRL)<VariationIndicator valueToday={volumeToday} valueYesterday={volumeYesterday} />
                  </h3>
                  <div>
                    <p className="mt-5"></p>
                    {listAtributes.map((item, index) => (
                      <Row key={index} className="mb-2">
                        <Col xs={6} className="d-flex flex-column">
                          <p className="h6 text-muted fw-semibold">{item.label}</p>
                        </Col>
                        <Col xs={3} className="d-flex flex-column text-end">
                          <p className="fw-semibold h6">{item.value}</p>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
                <Col md="auto" className="justify-content-center mt-md-4">
                  <RadialBarChart data={trustScore * 10} label="Rating" />
                </Col>
                <Col md="auto" className="justify-content-center d-none d-md-block mt-md-4">
                  <ExchangesDonut data={exchange} />
                </Col>
              </Row>
            </Container >
          </div >
          <Container className="overflow-auto p-2 my-5">
            <SymbolsTable data={exchange} full={exchange.reduce((a, e) => a + e.QuoteVolume, 0)} />
          </Container>
        </>
      );
    }
  }
}

export default ExchangeSummary;