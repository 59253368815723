import React from "react";
import { Row, Col, ListGroup, OverlayTrigger, Tooltip, Container } from 'react-bootstrap';
import ExportExcel from "./ExportExcel-closed";
import ProgressStackedBar from "../../charts/ProgressStackedBar";
import { formatBigNumbers } from "../../utils/priceFormats";

const RelatoriosHeader = (props) => {

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Inclui apenas o volume dos pares com BRL (R$ Real Brasileiro).
        </Tooltip>
    );
    const totalQuoteVolume = props.rawreport.reduce((a, e) => a + e.QuoteVolume, 0)
    const totalVolume = props.rawreport.reduce((a, e) => a + e.Volume, 0)
    return (
        <Container>
            <Row className="mt-0 px-2 w-100 mb-3">
                <Col md={5}>
                    <ProgressStackedBar data={props.dominance} />
                </Col>
                <Col>
                    <ListGroup.Item className="h6 mt-3 border-0 fw-semibold text-body text-center">
                        Volume {props.type === 'year' ? 'anual ' : 'mensal '}
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}>
                            <span className="badge rounded-pill bg-secondary text-white" style={{ fontSize: "70%" }}>i</span>
                        </OverlayTrigger>
                    </ListGroup.Item>
                    <Row>
                        <Col>
                            <ListGroup className='border-0 text-center'>
                                <ListGroup.Item className="h4 border-0 mt-3 py-0 fw-bold text-body">
                                    {totalQuoteVolume.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </ListGroup.Item>
                                <ListGroup.Item className="h6 py-0 border-0 fw-semibold text-body-secondary">                                        <span className="me-3">
                                    R$ {formatBigNumbers(totalQuoteVolume).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                </span>
                                    {props.symbol !== 'BRLBRL' ? (
                                        <span>
                                            {totalVolume.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 }) + ' ' + props.symbol.slice(0, -3)}
                                        </span>
                                    ) : ''}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>

                        <Col>
                            <ListGroup className='border-0 text-center'>
                                <ListGroup.Item className="h4 border-0 mt-3 py-0 fw-bold text-body">
                                    {props.series.length}
                                </ListGroup.Item>
                                <ListGroup.Item className="h6 py-0 border-0 fw-semibold text-body-secondary">
                                    Corretoras analisadas
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                </Col>
                <Col md="auto" className="my-auto">
                    <ExportExcel symbol={props.symbol} month={props.month} rawreport={props.rawreport} xaxistime={props.xaxistimeUTC} />
                </Col>
            </Row >
        </Container >
    )
}

export default RelatoriosHeader